import { useEffect, useState } from 'react';
import './Dashboard.scss';
import { axiosCalls } from '../../../../Api/_axios';
import { GiTakeMyMoney } from 'react-icons/gi';
import { RiLuggageDepositFill } from 'react-icons/ri';
import { FaUsers, FaFileInvoiceDollar, FaFileInvoice } from 'react-icons/fa';
import TopBar from '../../../../Components/TopBar/TopBar';
import Loader from '../../../../Components/Loader/Loader';

function Dashboard() {
  const token = sessionStorage.getItem('adminToken');
  const [KPI, setKPI] = useState<any>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axiosCalls(`sysadmin/kpis`, 'GET', null, token);
        setKPI(result.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="home_page">
      <TopBar />
      <div className="home_card">
        <div className="card">
          <div className="left dif">
            <div className="round">
              <RiLuggageDepositFill />
            </div>
          </div>
          <div className="right">
            <p>Total Businesses</p>
            <h2>{KPI.totalBusinesses.toLocaleString()}</h2>
          </div>
        </div>
        <div className="card">
          <div className="left ">
            <div className="round">
              <FaUsers />
            </div>
          </div>
          <div className="right">
            <p>Total Users</p>
            <h2>{KPI.totalUsers.toLocaleString()}</h2>
          </div>
        </div>
        <div className="card">
          <div className="left dif">
            <div className="round">
              <FaFileInvoiceDollar />
            </div>
          </div>
          <div className="right">
            <p>Total Invoice Value</p>
            <h2>₦{KPI.totalInvoiceValue.NGN.totalAmount.toLocaleString()}</h2>
            <h2>${KPI.totalInvoiceValue.USD.paid.toLocaleString()}</h2>
          </div>
        </div>
        <div className="card">
          <div className="left dif">
            <div className="round">
              <GiTakeMyMoney />
            </div>
          </div>
          <div className="right">
            <p>Paid Invoice Value</p>
            <h2>₦{KPI.totalInvoiceValue.NGN.paid.toLocaleString()}</h2>
            <h2>${KPI.totalInvoiceValue.USD.paid.toLocaleString()}</h2>
          </div>
        </div>
        <div className="card">
          <div className="left dif">
            <div className="round">
              <FaFileInvoice />
            </div>
          </div>
          <div className="right">
            <p>Outstanding Invoice Value</p>
            <h2>₦{KPI.totalInvoiceValue.NGN.outstanding.toLocaleString()}</h2>
            <h2>${KPI.totalInvoiceValue.USD.paid.toLocaleString()}</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
