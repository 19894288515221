import { useState, useEffect } from 'react';
import './BizReq.scss';
import { BiChevronRight } from 'react-icons/bi';
import axios, { AxiosError } from 'axios';
import Loader from '../../../../Assests/loader2.gif';
import TopBar from '../../../../Components/TopBar/TopBar';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../Components/Pagination/Pagination';

function BizReq() {
  const navigate = useNavigate();
  const [bizList, setBizList] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const token = sessionStorage.getItem('adminToken');

  const fetchData = async (num: number) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/sysadmin/business/fetch?limit=10&page=${num}&type=buyer_supplier`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setLoading(false);
        setBizList(res.data.data);
      }
    } catch (err) {
      const errStatus = err as AxiosError<Error>;
      if (errStatus.response) {
        if (errStatus.response.status === 401) {
          setLoading(false);
        } else {
          console.log('An Unexpected Error as occurred');
        }
      }
    }
  };

  useEffect(() => {
    fetchData(1);
  }, []);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const paginationClick = (num: any) => {
    setLoading(true);
    fetchData(num);
  };
  return (
    <>
      <TopBar />
      <div className="bizReq">
        <div className="locate">
          <p>paytton admin</p>
          <BiChevronRight />
          <h5>Business Requests </h5>
        </div>
        <div className="tablex">
          {loading ? (
            <div className="loader">
              <img src={Loader} alt="" />
            </div>
          ) : (
            <div className="table">
              <div className="head">
                <div className="row">
                  <div className="td">Company Name</div>
                  <div className="td"> Email</div>
                  <div className="td"> Status</div>
                  <div className="td"> Created Date</div>
                  <div className="td"> Activated Date</div>
                </div>
              </div>
              <div className="bodyx">
                {bizList.docs.map((item: any) => (
                  <div
                    role="button"
                    tabIndex={0}
                    className="row"
                    key={item._id}
                    onClick={() => navigate(`/admin/business/${item._id}`)}
                  >
                    <div className="td">{item.name}</div>
                    <div className="td"> {item.email}</div>
                    <div className="td"> {item.status}</div>
                    <div className="td"> {new Date(item.createdAt).toDateString()}</div>
                    <div className="td">
                      {item.activatedDate === null
                        ? 'Not Activated'
                        : new Date(item.activatedDate).toDateString()}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="mobile">
          {loading ? (
            <div className="loader">
              <img src={Loader} alt="" />
            </div>
          ) : (
            <>
              {bizList.docs.map((item: any) => (
                <div
                  role="button"
                  tabIndex={0}
                  className="nodyx"
                  key={item._id}
                  onClick={() => navigate(`/admin/business/${item._id}`)}
                >
                  <div className="row">
                    <div className="left">
                      <h2>Company Name</h2>
                    </div>
                    <div className="right">
                      <p>{item.name}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="left">
                      <h2>Email</h2>
                    </div>
                    <div className="right">
                      <p>{item.email}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="right">
                      <p>{item.type}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="left">
                      <h2>Status</h2>
                    </div>
                    <div className="right">
                      <p>{item.status}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="left">
                      <h2>Created Date</h2>
                    </div>
                    <div className="right">
                      <p>{new Date(item.createdAt).toDateString()}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="left">
                      <h2>Activated Date</h2>
                    </div>
                    <div className="right">
                      <p>
                        {item.activatedDate === null
                          ? 'Not Activated'
                          : new Date(item.activatedDate).toDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      {!loading && (
        <Pagination
          currentPage={currentPage}
          totalCount={bizList.totalPages}
          pageSize={10}
          onPageChange={(page: any) => {
            setCurrentPage(page);
            paginationClick(page);
          }}
        />
      )}
    </>
  );
}

export default BizReq;
