import React from 'react';
import './Button.scss';

interface Props {
  disabled?: boolean;
  light?: boolean;
  outlined?: boolean;
  large?: boolean;
  expanded?: boolean;
  loading?: boolean;
  danger?: boolean;
  textButton?: boolean;
  className?: string;
  rounded?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onForceClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  content: string;
}

const Button: React.FC<Props> = ({
  disabled,
  light,
  outlined,
  large,
  expanded,
  loading,
  danger,
  textButton,
  className,
  rounded,
  onClick,
  content,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!loading && !disabled) {
      onClick?.(event);
    }
  };
  const classNames = [
    className,
    'buttonComponent',
    light && 'light',
    outlined && 'outlined',
    large && 'large',
    expanded && 'expanded',
    danger && 'danger',
    textButton && 'textButton',
    disabled && 'disabled',
    rounded && 'rounded',
  ]
    .filter(Boolean)
    .join(' ');
  return (
    <button className={classNames} onClick={handleClick}>
      {content}
    </button>
  );
};

export default Button;
