import { useState, useEffect } from 'react';
import './BizReq.scss';
import { BiChevronRight } from 'react-icons/bi';
import axios from 'axios';
import Loader from '../../../../Assests/loader2.gif';
import TopBar from '../../../../Components/TopBar/TopBar';
import { useNavigate } from 'react-router-dom';

function BizUpdate() {
  const navigate = useNavigate();
  const [bizList, setBizList] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const token = sessionStorage.getItem('adminToken');

  const fetchData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/sysadmin/business/update-requests`;
      const params = {
        limit: 10,
      };
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const buyerSupplierResponse = await axios.get(url, {
        params: { ...params },
        headers,
      });
      if (buyerSupplierResponse.status === 200) {
        const mergedData = [...buyerSupplierResponse.data.data.docs];
        setBizList(mergedData);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <TopBar />
      <div className="bizReq">
        <div className="locate">
          <p>paytton admin</p>
          <BiChevronRight />
          <h5>Business Requests </h5>
        </div>
        <div className="tablex">
          {loading ? (
            <div className="loader">
              <img src={Loader} alt="" />
            </div>
          ) : (
            <div className="table">
              <div className="head">
                <div className="row">
                  <div className="td">Company Name</div>
                  <div className="td"> Email</div>
                  <div className="td"> Status</div>
                  <div className="td"> Date</div>
                </div>
              </div>
              <div className="bodyx">
                {bizList.map((item: any) => (
                  <div
                    role="button"
                    tabIndex={0}
                    className="row"
                    key={item._id}
                    onClick={() => navigate(`/admin/update-page/${item.business._id}`)}
                  >
                    <div className="td">{item.business.name}</div>
                    <div className="td"> {item.business.email}</div>
                    <div className="td"> {item.business.status}</div>
                    <div className="td">{new Date(item.updatedAt).toDateString()}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="mobile">
          {loading ? (
            <div className="loader">
              <img src={Loader} alt="" />
            </div>
          ) : (
            <>
              {bizList.map((item: any) => (
                <div
                  role="button"
                  tabIndex={0}
                  className="nodyx"
                  key={item._id}
                  onClick={() => navigate(`/admin/update-page/${item.business._id}`)}
                >
                  <div className="row">
                    <div className="left">
                      <h2>Company Name</h2>
                    </div>
                    <div className="right">
                      <p>{item.name}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="left">
                      <h2>Email</h2>
                    </div>
                    <div className="right">
                      <p>{item.email ? item.email : item.business.email}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="left">
                      <h2>Status</h2>
                    </div>
                    <div className="right">
                      <p>{item.status}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="left">
                      <h2>Date</h2>
                    </div>
                    <div className="right">
                      <p>{new Date(item.updatedAt).toDateString()}</p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default BizUpdate;
