import { AxiosError } from 'axios';
import { baseService } from '../hooks/queries-and-mutations';
import { errorHandler, getToken } from '.';
import { BankApiList, BusinessTypes, EmployeeType } from '../types/business.type';
import { toast } from 'react-toastify';

export const getBussiness = async (): Promise<BusinessTypes> => {
  try {
    const response = await baseService.get('business/user/fetch?limit=15', {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const getBanks = async (): Promise<BankApiList[]> => {
  try {
    const response = await baseService.get('business/banks/fetch', {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    if (response.data) {
      return (response.data || {})?.data;
    } else {
      return [];
    }
  } catch (error) {
    const err = error as AxiosError<Error>;
    toast.error('Sorry!, retrieving banks has failed');
    return errorHandler(err);
  }
};

export const getBankDetails = async (payload: any) => {
  try {
    const response = await baseService.post('business/banks/account-name', payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    if (response.data) {
      return (response.data || {})?.data;
    } else {
      return [];
    }
  } catch (error) {
    return toast.error('Invalid details provided, please check and try again');
  }
};

export const createBusiness = async (payload: any) => {
  try {
    const response = await baseService.post('/business/create', payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const getPermission = async (businessId: any) => {
  try {
    const response = await baseService.get(`/role/${businessId}/fetch-one`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const updateBusiness = async (businessId: string, payload: any) => {
  try {
    const response = await baseService.put(`/business/${businessId}/update`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const getEmployee = async (businessId: any, page: any): Promise<EmployeeType> => {
  try {
    const response = await baseService.get(
      `business/${businessId}/member/fetch?limit=10&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const creatEmployee = async (businessId: any, payload: any) => {
  try {
    const response = await baseService.post(
      `/business/${businessId}/member/add`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const updateEmployee = async (businessId: any, employeeId: any, payload: any) => {
  try {
    const response = await baseService.put(
      `/business/${businessId}/member/${employeeId}/update`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const updateBusinessLogo = async (businessId: any, payload: any) => {
  try {
    const response = await baseService.put(
      `/business/${businessId}/update-logo`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};
