import { useState } from 'react';
import './Auth.scss';
import Loader from '../../Assests/loader.gif';
import Logo from '../../Assests/2.png';
import { ReactComponent as Enter } from '../../Assests/Enter.svg';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';

type FormData = {
  email: string;
  name: string;
  companyName: string;
  companyDescription: string;
  companyWebsite: string;
  vendorCount: number;
  teamSize: number;
};
interface Props {
  notify: (type: string, message: string) => void;
}
function EarlyAccess({ notify }: Props) {
  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const onSubmit = async (data: FormData) => {
    setFormLoading(true);
    const payLoad = {
      email: data.email,
      name: data.name,
      companyName: data.companyName,
      companyDescription: data.companyDescription,
      companyWebsite: data.companyWebsite,
      vendorCount: data.vendorCount,
      teamSize: data.teamSize,
    };
    try {
      const baseUrl = process.env.REACT_APP_API_URL; // Get the base URL from the environment variable
      const response = await axios.post(`${baseUrl}/auth/contact`, payLoad);
      notify('success', response.data.message);
      setFormLoading(false);
      setTimeout(() => {
        navigate('/');
      }, 1000);
    } catch (error: any) {
      setFormLoading(false);
      notify('error', error.response.data.errors.join(','));
    }
  };

  return (
    <div className="register">
      <div className="left early">
        <div className="left-top">
          <img src={Logo} alt="" onClick={() => navigate('/')} />
        </div>
        <form className="early" onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <p>Name</p>
            <input type="text" {...register('name', { required: true })} id="" />
            {errors.name && <span>This field is required</span>}
          </div>
          <div className="card">
            <p>Email</p>
            <input
              type="Enter your work email"
              id=""
              {...register('email', { required: true })}
            />
            {errors.email && <span>This field is required</span>}
          </div>
          <div className="card">
            <p>Company Name</p>
            <input type="text" {...register('companyName', { required: true })} id="" />
            {errors.companyName && <span>This field is required</span>}
          </div>
          <div className="card">
            <p>Team size</p>
            <input type="number" {...register('teamSize', { required: true })} id="" />
            {errors.teamSize && <span>This field is required</span>}
          </div>
          <div className="card">
            <p>Website</p>
            <input
              type="text"
              {...register('companyWebsite', { required: true })}
              id=""
            />
            {errors.companyWebsite && <span>This field is required</span>}
          </div>
          <div className="card">
            <p># of vendors</p>
            <input type="number" {...register('vendorCount', { required: true })} id="" />
            {errors.vendorCount && <span>This field is required</span>}
          </div>
          <div className="card early">
            <p>Company Description</p>
            <textarea
              id=""
              {...register('companyDescription', { required: true })}
              cols={30}
              rows={10}
            ></textarea>
            {errors.companyDescription && <span>This field is required</span>}
          </div>
          <div className="cardd">
            <input
              type="checkbox"
              name=""
              id=""
              onChange={(event) => {
                setIsChecked(event.target.checked);
              }}
            />
            <p>I accept the terms of use and conditions governing Paytton</p>
          </div>
          <div className="card early">
            {formLoading ? (
              <div className="loader">
                <img src={Loader} alt="" />
              </div>
            ) : (
              <div className="buttons">
                <button type="submit" disabled={!isChecked}>
                  <p>Receive Early Access</p>
                  <Enter />
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
      {/* <div className='right'>
        <div className='right-top'>
          <img src={Image} alt='' />
        </div>
        <div className='right-body'>
          <h2>A comfirmation mail has been sent to you</h2>
          <p>
            Check your mail you will be notified immediately payment is made to
            the provide account details
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default EarlyAccess;
