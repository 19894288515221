import { useMemo, useState, useRef } from 'react';
import Invoice from './Invoice';
import Breadcrumb from '../../../../Components/Breadcrumb';
import Button from '../../../../Components/Form/Button';
import { Icon, Icons } from '../../../../Components/Icon';
import ConfirmModal from '../../../../Components/Modal/ConfirmModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import DeclineModal from '../../../../Components/Modal/Invoice/DeclineModal';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import SendReminderModal from '../../../../Components/Modal/Invoice/SendReminderModal';
import ProofOfPaymentModal from '../../../../Components/Modal/Invoice/ProofOfPaymentModal';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { useGetInvoice } from '../../../../hooks/queries-and-mutations/invoice';
import Loader from '../../../../Assests/loader.gif';
import ReceiveableApprovalFlow from './ReceiveableApprovalFlow';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const EhancedDeclineModal = withCreatePortal(DeclineModal);
const EhancedSuccessModal = withCreatePortal(SuccessModal);
const EhancedSendReminderModal = withCreatePortal(SendReminderModal);
const EhancedProofOfPaymentModal = withCreatePortal(ProofOfPaymentModal);

export default function InvoiceView() {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showSuccessModalForReminder, setShowSuccessModalForReminder] = useState(false);
  const [showPaymentProofModal, setShowPaymentProofModal] = useState(false);
  const { invoiceId } = useParams();
  const { docs } = useAppSelector(selectBusiness);
  const {
    data: invoice,
    isLoading,
    refetch,
  } = useGetInvoice({
    businessId: docs[0].businessID._id,
    invoiceId: invoiceId!,
  });

  const pdfRef = useRef(null);

  const onExportPDF = () => {
    if (pdfRef.current) {
      const width = document.body.clientWidth;
      const height = document.body.clientHeight;

      html2canvas(document.body, {
        width: width,
        height: height,
        scale: 1,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'pt', [width, height]);
        pdf.addImage(imgData, 'PNG', 0, 0, width, height);
        pdf.save('invoice.pdf');
      });
    }
  };

  // const isPayableInvoice = useMemo(() => {
  //   return invoice?.invoice.sellerID._id === docs[0].businessID._id;
  // }, [docs, invoice?.invoice.sellerID._id]);

  const isReceiveableInvoice = useMemo(() => {
    return invoice?.invoice.businessID._id === docs[0].businessID._id;
  }, [docs, invoice?.invoice.businessID._id]);

  return (
    <div>
      {isLoading ? (
        <div className="flex flex-col items-center justify-center mt-24">
          <img src={Loader} className="w-32" alt="" />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between py-5">
            <Breadcrumb text="Invoices" subText={invoice?.invoice?.number} />
            <div className="flex items-center gap-3">
              <Button
                type="button"
                variant="transparent"
                size="custom"
                label="Export as PDF"
                className="px-5 py-2.5 border border-[#0353A4] text-sm bg-transparent text-[#0353A4] cursor-pointer"
                icon={<Icon name={Icons.Download} fill="#8E8E8E80" />}
                iconPosition="left"
                iconLabelSpacing={3}
                onClick={onExportPDF}
              />
            </div>
          </div>

          <div className="panel p-5 rounded-[20px] bg-white flex flex-col" ref={pdfRef}>
            <p className="text-base font-bold text-black font-inter">Status</p>

            {/* {isPayableInvoice && invoice?.invoice.buyerApprovalNextLevel && (
              <PayableApprovalFlow
                buyerApprovalNextLevel={invoice?.invoice.buyerApprovalNextLevel}
                invoice={invoice?.invoice}
                getInvoice={refetch}
              />
            )} */}
            {isReceiveableInvoice && invoice?.invoice.sellerApprovalNextLevel && (
              <ReceiveableApprovalFlow
                sellerApprovalNextLevel={invoice?.invoice.sellerApprovalNextLevel}
                invoice={invoice?.invoice}
                getInvoice={refetch}
              />
            )}
          </div>
          {/* Integrate the Invoice component here */}
          <Invoice invoice={invoice?.invoice} />
        </>
      )}

      {showDeclineModal && (
        <EhancedDeclineModal onCancel={() => setShowDeclineModal(false)} />
      )}

      {showReminderModal && (
        <EhancedSendReminderModal
          onCancel={() => setShowReminderModal(false)}
          onSuccess={() => {
            setShowReminderModal(false);
            setShowSuccessModalForReminder(true);
          }}
        />
      )}

      {showPaymentProofModal && (
        <EhancedProofOfPaymentModal onCancel={() => setShowPaymentProofModal(false)} />
      )}

      {showSuccessModalForReminder && (
        <EhancedSuccessModal
          title="Reminder Sent"
          description="A reminder has been sent to Favorite Promise"
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmModal
          content={'Are you sure you want to approve this invoice ?'}
          onConfirm={() => {
            setShowSuccessModal(true);
            setShowConfirmModal(false);
          }}
          onCancel={() => setShowConfirmModal(false)}
          cancelText="Cancel"
          actionText="Approve"
          cancelClassName="w-[7rem] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-[7rem] px-5 py-2.5 text-sm bg-[#061A40] rounded font-normal text-white"
        />
      )}

      {showSuccessModal && (
        <EhancedSuccessModal
          title="Invoice Approved"
          description="You have approved this invoice "
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
          }}
        />
      )}
    </div>
  );
}
