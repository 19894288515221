import React, { useState } from 'react';
import Button from '../../../../../Components/Form/Button';
import Plan from './Plan';
import Subscription from './Subscription';
import { useGetCurrentPlan } from '../../../../../hooks/queries-and-mutations/subscription';
import { useAppSelector } from '../../../../../store/hooks';
import { selectBusiness } from '../../../../../selectors/business-selector';

export default function SubscriptionMenu() {
  const { docs } = useAppSelector(selectBusiness);
  const { data: currentPlan } = useGetCurrentPlan({ businessId: docs[0].businessID._id });
  const [showPlan, setShowPlan] = useState(false);

  return (
    <div className="mt-10">
      {!showPlan && !currentPlan && (
        <div className="bg-[#B9D6F224] rounded-md p-9">
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <p className="font-medium text-lg">Choose a plan</p>
              <span className="font-normal text-black/40 text-sm">
                You are currently not subscribed to any plan
              </span>
            </div>
            <Button
              type="button"
              variant="custom"
              onClick={() => setShowPlan(true)}
              size="custom"
              label="Subscription plan"
              className="py-2.5 px-4 bg-[#0353A4] text-white"
            />
          </div>
        </div>
      )}

      {currentPlan && <Subscription currentPlan={currentPlan} />}
      {showPlan && <Plan />}
    </div>
  );
}
