import { useCallback, useEffect, useState } from 'react';
import Breadcrumb from '../../../../Components/Breadcrumb';
import { Icon, Icons } from '../../../../Components/Icon';
import EmptyRecord from '../../../../Components/EmptyRecord';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { deleteInvoice, getInvoices } from '../../../../service/invoice';
import { InvoiceDoc, InvoiceType } from '../../../../types/invoice.type';
import Loader from '../../../../Assests/loader.gif';
import { toast } from 'react-toastify';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import { useGlobalContext } from '../../../../Context/Context';
import { useNavigate } from 'react-router-dom';

export default function InvoiceDraft() {
  const [isLoading, setIsLoading] = useState(false);
  const [invoicesData, setInvoicesData] = useState<InvoiceDoc[]>([]);
  const [currentPage] = useState(1);
  const [invoiceIdToDelete, setInvoiceIdToDelete] = useState<string>('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { setSelectedInvoiceId } = useGlobalContext();

  const { docs } = useAppSelector(selectBusiness);
  const navigate = useNavigate();

  const fetchData = useCallback(
    async (page: number, filterBy?: string) => {
      try {
        setIsLoading(true);
        const response: InvoiceType = await getInvoices(
          docs[0].businessID._id,
          filterBy,
          page
        );
        const draftInvoices = response.docs.filter(
          (invoice) => invoice.status === 'draft'
        );
        setInvoicesData(draftInvoices);
      } catch (error) {
        console.error('Failed to fetch invoices:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [docs]
  );

  useEffect(() => {
    setIsLoading(true);
    fetchData(currentPage, 'supplier');
  }, []);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-GB', { month: 'short' });
    const year = date.getFullYear();
    return `${day}, ${month} ${year}`;
  };

  const handleDeleteInvoice = async () => {
    try {
      if (invoiceIdToDelete) {
        await deleteInvoice(docs[0].businessID._id, invoiceIdToDelete);

        toast.success('Invoice deleted successfully');
        setShowDeleteConfirmation(false);

        setShowSuccessModal(true);

        const res = invoicesData.filter((val) => val?._id !== invoiceIdToDelete);
        setInvoicesData(res);

        fetchData(currentPage); // Fetch updated invoice list
      }
    } catch (error) {
      toast.error('Error deleting Invoice');
    }
  };

  return (
    <div>
      <Breadcrumb text="Invoices" subText="Create invoice" innerText="Save drafts" />
      <div className="mt-10">
        {isLoading ? (
          <div className="flex flex-col items-center justify-center mt-24">
            <img src={Loader} className="w-32" alt="Loading" />
          </div>
        ) : (
          <div className="relative overflow-x-auto mt-5 pb-32 panel bg-[#0353A40F] rounded-[20px]">
            {invoicesData.length > 0 ? (
              <table className="w-full mt-3 text-sm font-medium text-left text-black font-inter">
                <thead className="text-sm text-left border-b border-black/10 font-inter text-black/60">
                  <tr>
                    <th>Vendor</th>
                    <th>Amount</th>
                    <th>Date Saved</th>
                    <th>Due Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="bg-[#B9D6F224]">
                  {invoicesData.map((invoice) => (
                    <tr className="bg-white/60" key={invoice._id}>
                      <td className="px-6 py-4">{invoice.sellerName}</td>
                      <td className="px-6 py-4">
                        &#x20A6;
                        {invoice.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </td>
                      <td className="px-6 py-4">{formatDate(invoice.createdAt)}</td>
                      <td className="px-6 py-4">{formatDate(invoice.requiredEnd)}</td>
                      <td className="flex items-center gap-[20px] px-6 py-4">
                        <button
                          className=""
                          type="button"
                          onClick={() => {
                            setInvoiceIdToDelete(invoice._id);
                            setShowDeleteConfirmation(true);
                          }}
                        >
                          <Icon name={Icons.Delete} />
                        </button>
                        <button
                          className=""
                          type="button"
                          onClick={() => {
                            setSelectedInvoiceId(invoice._id);
                            setInvoiceIdToDelete(invoice._id);
                            navigate('/invoices/create');
                          }}
                        >
                          <Icon name={Icons.EditPencil} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <EmptyRecord
                title="No Drafts to Show Yet"
                content="You’ve got a blank state."
                icon={Icons.Draft}
              />
            )}
          </div>
        )}
      </div>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 overflow-y-auto mt-52">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <Icon name={Icons.Delete} />
                  </div>

                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Delete Invoice
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this draft?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleDeleteInvoice}
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Delete
                </button>
                <button
                  onClick={() => setShowDeleteConfirmation(false)}
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showSuccessModal && (
        <SuccessModal
          title="Invoice Deleted"
          description="The invoice has been successfully deleted."
          onCancel={() => setShowSuccessModal(false)}
          cancelText="OK"
        />
      )}
    </div>
  );
}
