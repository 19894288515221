import { useState } from 'react';
import ModalPortal from '../../ModalPortal';
import Upload from '../../Form/Upload';
import UploadPreview from '../../Form/UploadPreview';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Icon, Icons } from '../../Icon';
import Button from '../../Form/Button';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
};

export default function AddBulkVendorModal({ onCancel }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      vendor: null,
    },
    validationSchema: Yup.object({
      vendor: Yup.string().required('Upload vendor file is required').nullable(),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      console.log(
        '🚀 ~ file: AddAccountModal.tsx:39 ~ AddAccountModal ~ values:',
        values
      );

      try {
        // dispatch(login({ email: email.trim(), password: password.trim() }));
      } catch (error) {
        console.log('Error', error);
      }
    },
  });

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/csvFile.csv`;
    link.download = 'paython-template.csv';
    link.click();
  };

  return (
    <div>
      <ModalPortal>
        <div className="fixed top-0 z-50 flex h-screen w-screen items-center justify-center bg-[#000000] bg-opacity-60">
          <div className="w-2/5">
            <div className="flex flex-col items-end justify-end">
              <div
                className="cursor-pointer"
                role="button"
                tabIndex={0}
                onClick={onCancel}
              >
                <Icon name={Icons.Close} />
              </div>
            </div>
            <div className="mr-12 rounded-[20px] bg-white p-8 font-circular text-2xl font-normal text-[#353535]">
              <div className="flex flex-col">
                <div className="py-5 text-left border-b">
                  <p className="text-xl font-medium text-black font-inter">
                    Bulk upload in csv{' '}
                  </p>
                  <p className="text-sm font-normal font-inter text-black/40">
                    Search or invite vendor if they are not registered yet.
                  </p>
                </div>

                <div className="w-full mx-auto">
                  <form>
                    {!formik.values.vendor ? (
                      <Upload
                        error={formik.errors.vendor ?? ''}
                        label="Vendor"
                        getFile={(file) => formik.setFieldValue('vendor', file)}
                      />
                    ) : (
                      <UploadPreview
                        fileName="vendor.pdf"
                        removeFile={() => formik.setFieldValue('vendor', '')}
                      />
                    )}
                    <div
                      role="button"
                      tabIndex={0}
                      className="flex items-center justify-center gap-2 my-4 cursor-pointer"
                      onClick={handleDownload}
                    >
                      <Icon name={Icons.Download} />
                      <p className="text-[#0353A4] text-sm font-inter font-medium">
                        Download template here
                      </p>
                    </div>
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      label="Submit"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      className="mt-10"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalPortal>
    </div>
  );
}
