import React, { useState } from 'react';
import Logo from '../Assests/logo-light.png';
import { NavLink, Outlet } from 'react-router-dom';
// import EarlyAccess from '../Pages/Landing/components/EarlyAccess'
import '../style/landing.css';
import withCreatePortal from '../Components/Hoc/withCreatePortal';
import EarlyAccess from '../Components/Modal/EarlyAccess';
import { Icon, Icons } from '../Components/Icon';
import MobileMenu from './SidebarLayout/MobileMenu';

const EnhancedEarlyAccessModal = withCreatePortal(EarlyAccess);
export default function LandingLayout() {
  const [showEarlyAccess, setShowEarlyAccess] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <>
      <div>
        {/* Navbar Start */}
        <header className="text-sm font-medium navbar-landing nav-dark-landing font-inter">
          <div className="container bg-white">
            <nav>
              {/* Navbar Brand Logo */}
              <a href="/" className="logo">
                <img src={Logo} className="w-40 h-8 logo-dark" alt="Paytton" />
              </a>
              <div
                role="button"
                tabIndex={0}
                className="lg:hidden flex items-center ms-auto px-2.5"
                onClick={() => setShowMobileMenu((prevState) => !prevState)}
              >
                <button
                  className="inline-flex items-center justify-center w-12 hs-collapse-toggle h-9"
                  type="button"
                >
                  <Icon name={Icons.Menu} />
                </button>
              </div>

              {/* Nevigation Menu */}
              <div className="items-center justify-center hidden mx-auto mt-2 overflow-hidden transition-all duration-300 hs-collapse lg:basis-auto basis-full grow lg:flex lg:mt-0">
                <ul className="navbar-landing-nav">
                  {/* Home Page Link */}
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      About
                    </a>
                  </li>
                  {/* Features Page Link */}
                  <li className="nav-item">
                    <a className="nav-link" href="#feature">
                      Features
                    </a>
                  </li>
                  {/* Pricing Page Link */}
                  <li className="nav-item">
                    <a className="nav-link" href="#pricing">
                      Pricing
                    </a>
                  </li>
                  {/* Faq Page Link */}
                  <li className="nav-item">
                    <a className="nav-link" href="#faq">
                      Faq
                    </a>
                  </li>
                </ul>
              </div>

              {/* Download Button */}
              <div className="items-center hidden gap-5 lg:flex">
                <button
                  type="button"
                  onClick={() => setShowEarlyAccess(true)}
                  className="nav-btn border border-[#061A40] text-[#061A40]"
                >
                  Get early access
                </button>
                <NavLink
                  to="/auth/login"
                  className="nav-btn bg-[#061A40] text-white w-24"
                >
                  Sign in
                </NavLink>
              </div>
            </nav>
            {/* end nav */}
            {/* Mobile Navigation Menu */}
            {showMobileMenu && (
              <MobileMenu
                setShowEarlyAccess={setShowEarlyAccess}
                toggleMenu={setShowMobileMenu}
              />
            )}
          </div>
        </header>
        {/* Navbar End */}
        {showEarlyAccess && (
          <EnhancedEarlyAccessModal
            onCancel={() => setShowEarlyAccess(false)}
            onConfirm={() => setShowEarlyAccess(false)}
          />
        )}
      </div>
      <div className="relative pt-20 overflow-hidden lg:pt-16 active">
        <Outlet />
      </div>
    </>
  );
}
