import { Icon, Icons } from '../../Icon';
import Button from '../../Form/Button';
import logo from '../../../Assests/logo-dark.png';
import { InfoModal } from '../InfoModal';
import { TransactionDoc } from '../../../types/transaction.type';
import { format } from 'date-fns';
import { formatCurrency } from '../../../utils';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  transaction: TransactionDoc;
};

export default function TransactionDetailModal({ onCancel, transaction }: Props) {
  return (
    <InfoModal width="w-[50%]" className="h-fit " onClose={onCancel}>
      <div className="rounded-[20px] bg-white p-8 font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col">
          <div className="flex items-center justify-between py-5 text-left">
            <img src={logo} alt="logo" />
            <p className="text-xl font-medium text-black font-inter">
              Transaction Detail
            </p>
          </div>

          <div className="w-full mx-auto my-8">
            <div className="flex flex-col items-center justify-center">
              <p className="text-sm font-medium text-black font-inter">
                Transaction Amount
              </p>
              <p className="text-2xl font-semibold text-black font-inter">
                {formatCurrency(transaction.currency, transaction.amount)}
              </p>
            </div>
            <div className="pl-4 mt-9">
              <div className="flex items-center justify-between py-4 border-b">
                <span className="text-sm font-medium text-black/50">Vendor</span>
                <span className="text-sm font-medium text-right text-black">
                  {transaction.vendorName}
                </span>
              </div>
              <div className="flex items-center justify-between py-4 border-b">
                <span className="text-sm font-medium text-black/50">Invoice number</span>
                <span className="text-sm font-medium text-right text-black">
                  {transaction.invoiceNumber}
                </span>
              </div>
              <div className="flex items-center justify-between py-4 border-b">
                <span className="text-sm font-medium text-black/50">Date and time</span>
                <span className="text-sm font-medium text-right text-black">{`${format(
                  new Date(transaction.createdAt),
                  'dd, MMM yyyy'
                )} | ${format(new Date(transaction.createdAt), 'HH:mm:ss')}`}</span>
              </div>
              <div className="flex items-center justify-between py-4 border-b">
                <span className="text-sm font-medium text-black/50">Description</span>
                <span className="text-sm font-medium text-right text-black">
                  {transaction.paidAt}
                </span>
              </div>
              <div className="flex items-center justify-between py-4 border-b">
                <span className="text-sm font-medium text-black/50">
                  Transaction refrence
                </span>
                <span className="text-sm font-medium text-right text-black">
                  {transaction.id}
                </span>
              </div>
              <div className="flex items-center justify-between py-4 border-b">
                <span className="text-sm font-medium text-black/50">Payment type</span>
                <span className="text-sm font-medium text-right text-black">
                  {transaction.paymentType}
                </span>
              </div>
            </div>

            <a
              href={transaction.paymentProof}
              rel="noreferrer"
              download={transaction.paymentProof}
              target="_blank"
            >
              <Button
                type="button"
                variant="primary"
                size="lg"
                label="Download Receipt"
                className="mt-10"
                icon={<Icon name={Icons.Download} fill="#FFFFFF" />}
                iconPosition="left"
                iconLabelSpacing={10}
              />
            </a>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
