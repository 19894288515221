import { AxiosError } from 'axios';
import { baseService, errorHandler, getToken } from './';
import { SingleVendorType, VendorType } from '../types/vendor.type';

export const getVendors = async (
  businessId: any,
  page: any,
  type?: any,
  limit = 10
): Promise<VendorType> => {
  try {
    let query = '';
    if (type) {
      query = `&type=${type}`;
    }
    const response = await baseService.get(
      `business/${businessId}/connection/fetch?limit=${limit}&page=${page}${query}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const inviteVendor = async (businessId: any, payload: any): Promise<void> => {
  try {
    const response = await baseService.post(
      `business/${businessId}/connection/invite`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const createVendor = async (businessId: any, payload: any): Promise<void> => {
  try {
    const response = await baseService.post(
      `/business/${businessId}/connection/add`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const updateVendor = async (
  businessId: string,
  vendorId: string,
  payload: any
): Promise<void> => {
  try {
    const response = await baseService.put(
      `/business/${businessId}/connection/update/${vendorId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const searchVendor = async (payload: any) => {
  try {
    const response = await baseService.post(`/business/search`, payload, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const getVendor = async (
  businessId: any,
  vendorId: any
): Promise<SingleVendorType> => {
  try {
    const response = await baseService.get(
      `business/${businessId}/connection/${vendorId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const getVendorTotal = async (
  businessId: any,
  vendorId: any
): Promise<SingleVendorType> => {
  try {
    const response = await baseService.get(`transaction/${businessId}/${vendorId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const blacklistVendor = async (
  businessId: any,
  vendorId: any,
  payload: any
): Promise<void> => {
  try {
    const response = await baseService.put(
      `business/${businessId}/connection/blacklist/${vendorId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const approveVendor = async (businessId: any, vendorId: any): Promise<any> => {
  try {
    const response = await baseService.put(
      `business/${businessId}/approve?id=${vendorId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};

export const declineVendor = async (
  businessId: any,
  payload: any,
  vendorId: any
): Promise<any> => {
  try {
    const response = await baseService.put(
      `business/${businessId}/decline?id=${vendorId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return (response.data || {})?.data;
  } catch (error) {
    const err = error as AxiosError<Error>;
    return errorHandler(err);
  }
};
