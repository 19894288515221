import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '../../../../../Components/Form/Button';
import TextField from '../../../../../Components/Form/TextField';
import UploadPreview from '../../../../../Components/Form/UploadPreview';
import Upload from '../../../../../Components/Form/Upload';
import { Icon, Icons } from '../../../../../Components/Icon';
import { BusinessId, KYBDto } from '../../../../../types/business.type';

type Props = {
  onSave: (values: KYBDto) => void;
  business: BusinessId;
};

export default function EditCompliance({ onSave, business }: Readonly<Props>) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const directors = useMemo(() => {
    if (business && (business?.directorsDetails ?? []).length) {
      return business?.directorsDetails.map((dirc) => ({
        fullName: dirc.fullName,
        number: dirc.number,
        type: dirc.type,
      }));
    } else {
      return [
        {
          fullName: '',
          number: '',
          type: '',
        },
      ];
    }
  }, [business]);

  const representatives = useMemo(() => {
    if (business && (business?.repsDetails ?? []).length) {
      return business?.repsDetails.map((rep) => ({
        firstName: rep.firstName,
        lastName: rep.lastName,
        email: rep.email,
        position: rep.position,
      }));
    } else {
      return [
        {
          firstName: '',
          lastName: '',
          email: '',
          position: '',
        },
      ];
    }
  }, [business]);

  const formik = useFormik({
    initialValues: {
      businessFile: null,
      utilityFile: null,
      identificationFile: null,
      licenseFile: null,
      directors,
      representatives,
    } as KYBDto,
    validationSchema: Yup.object({
      businessFile: Yup.mixed().nullable(),
      utilityFile: Yup.mixed().nullable(),
      identificationFile: Yup.mixed().nullable(),
      licenseFile: Yup.mixed().nullable(),
      directors: Yup.array().of(
        Yup.object().shape({
          fullName: Yup.string().nullable(),
          number: Yup.string().matches(/^\d+$/, 'ID Number must be numeric').nullable(),
          type: Yup.string().nullable(),
        })
      ),
      representatives: Yup.array().of(
        Yup.object().shape({
          firstName: Yup.string()
            .matches(/^[A-Za-z]+$/, 'First name must contain only letters')
            .nullable(),
          lastName: Yup.string()
            .matches(/^[A-Za-z]+$/, 'Last name must contain only letters')
            .nullable(),
          email: Yup.string().email('Invalid email format').nullable(),
          position: Yup.string().nullable(),
        })
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      setIsSubmitting(true);
      onSave(values as KYBDto);
      setIsSubmitting(false);
      resetForm({ values });
    },
  });

  const addMoreDirector = () => {
    const newDirectors = [
      ...formik.values.directors,
      {
        fullName: '',
        number: '',
        type: '',
      },
    ];
    formik.setFieldValue('directors', newDirectors);
  };

  const addMoreRepresentative = () => {
    const newRepresentatives = [
      ...formik.values.representatives,
      {
        firstName: '',
        lastName: '',
        email: '',
        position: '',
      },
    ];
    formik.setFieldValue('representatives', newRepresentatives);
  };

  return (
    <div>
      <div className="p-8">
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-8">
            <div className="border-b border-[#0000000D] pb-2">
              <h2 className="text-xl text-[#263238] font-semibold">KYB Documents</h2>
            </div>

            <div className="grid flex-1 grid-cols-1 gap-x-14 gap-y-2 sm:grid-cols-2">
              {!formik.values.businessFile ? (
                <Upload
                  error={
                    formik.errors.businessFile ? 'Business registration is required' : ''
                  }
                  label="Business Registration"
                  fileName="Businessregistration"
                  getFile={(file) => {
                    formik.setFieldValue('businessFile', file);
                  }}
                />
              ) : (
                <UploadPreview
                  fileName={formik.values.businessFile?.name ?? ''}
                  removeFile={() => {
                    formik.setFieldValue('businessFile', null);
                  }}
                />
              )}
              {!formik.values.utilityFile ? (
                <Upload
                  error={formik.errors.utilityFile ? 'Utility bill is required' : ''}
                  label="Utility Bill"
                  fileName="Utilitybill"
                  getFile={(file) => {
                    formik.setFieldValue('utilityFile', file);
                  }}
                />
              ) : (
                <UploadPreview
                  fileName={formik.values.utilityFile?.name ?? ''}
                  removeFile={() => {
                    formik.setFieldValue('utilityFile', null);
                  }}
                />
              )}
              {!formik.values.identificationFile ? (
                <Upload
                  error={
                    formik.errors.identificationFile
                      ? 'Proof of identification is required'
                      : ''
                  }
                  label="Proof of Identification"
                  fileName="Proofofidentification"
                  getFile={(file) => {
                    formik.setFieldValue('identificationFile', file);
                  }}
                />
              ) : (
                <UploadPreview
                  fileName={formik.values.identificationFile?.name ?? ''}
                  removeFile={() => {
                    formik.setFieldValue('identificationFile', null);
                  }}
                />
              )}
              {!formik.values.licenseFile ? (
                <Upload
                  error={formik.errors.licenseFile ? 'License is required' : ''}
                  label="License"
                  fileName="License"
                  getFile={(file) => {
                    formik.setFieldValue('licenseFile', file);
                  }}
                />
              ) : (
                <UploadPreview
                  fileName={formik.values.licenseFile?.name ?? ''}
                  removeFile={() => {
                    formik.setFieldValue('licenseFile', null);
                  }}
                />
              )}
            </div>
          </div>
          {/* Director */}
          <div className="mt-12">
            <div className="border-b border-[#0000000D] pb-2">
              <h2 className="text-xl text-[#263238] font-semibold">
                Director's Information
              </h2>
            </div>
            <div className="mt-6">
              {formik.values.directors.map((director, index) => (
                <div key={index} className="mt-10">
                  <div className="grid flex-1 grid-cols-1 gap-x-14 gap-y-2 sm:grid-cols-2">
                    <TextField
                      name={`directors[${index}].fullName`}
                      type="text"
                      value={director.fullName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleChange}
                      label="Director Fullname"
                      error={
                        formik.touched.directors
                          ? formik.errors.directors?.[index] &&
                            formik.errors.directors.length > 0
                            ? JSON.parse(JSON.stringify(formik.errors.directors[index]))
                                .fullName
                            : ''
                          : ''
                      }
                      className="bg-[#FFFFFF8C]"
                    />
                    <TextField
                      name={`directors[${index}].number`}
                      type="text"
                      value={director.number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleChange}
                      label="ID Number"
                      error={
                        formik.touched.directors
                          ? formik.errors.directors?.[index] &&
                            formik.errors.directors.length > 0
                            ? JSON.parse(JSON.stringify(formik.errors.directors[index]))
                                .number
                            : ''
                          : ''
                      }
                      className="bg-[#FFFFFF8C]"
                    />
                    <TextField
                      name={`directors[${index}].type`}
                      type="text"
                      value={director.type}
                      onChange={formik.handleChange}
                      onBlur={formik.handleChange}
                      label="Type"
                      error={
                        formik.touched.directors
                          ? formik.errors.directors?.[index] &&
                            formik.errors.directors.length > 0
                            ? JSON.parse(JSON.stringify(formik.errors.directors[index]))
                                .type
                            : ''
                          : ''
                      }
                      className="bg-[#FFFFFF8C]"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div
              className="flex items-center mt-8"
              role="button"
              tabIndex={0}
              onClick={addMoreDirector}
            >
              <div className="flex items-center gap-1">
                <Icon name={Icons.Add} />
                <span className="text-sm font-medium text-[#000000]">
                  Add another director
                </span>
              </div>
              <div className="ml-5 line" style={{ width: '81.3%' }} />
            </div>
          </div>
          {/* Representative */}
          <div className="mt-12">
            <div className="border-b border-[#0000000D] pb-2">
              <h2 className="text-xl text-[#263238] font-semibold">
                Representative Information
              </h2>
            </div>
            <div className="mt-6">
              {formik.values.representatives.map((rep, index) => (
                <div key={index} className="mt-10">
                  <div className="grid flex-1 grid-cols-1 gap-x-14 gap-y-2 sm:grid-cols-2">
                    <TextField
                      name={`representatives[${index}].firstName`}
                      type="text"
                      value={rep.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleChange}
                      label="Representative First Name"
                      error={
                        formik.touched.representatives
                          ? formik.errors.representatives?.[index] &&
                            formik.errors.representatives.length > 0
                            ? JSON.parse(
                                JSON.stringify(formik.errors.representatives[index])
                              ).firstName
                            : ''
                          : ''
                      }
                      className="bg-[#FFFFFF8C]"
                    />
                    <TextField
                      name={`representatives[${index}].lastName`}
                      type="text"
                      value={rep.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleChange}
                      label="Representative Last Name"
                      error={
                        formik.touched.representatives
                          ? formik.errors.representatives?.[index] &&
                            formik.errors.representatives.length > 0
                            ? JSON.parse(
                                JSON.stringify(formik.errors.representatives[index])
                              ).lastName
                            : ''
                          : ''
                      }
                      className="bg-[#FFFFFF8C]"
                    />
                    <TextField
                      name={`representatives[${index}].email`}
                      type="text"
                      value={rep.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleChange}
                      label="Representative Email"
                      error={
                        formik.touched.representatives
                          ? formik.errors.representatives?.[index] &&
                            formik.errors.representatives.length > 0
                            ? JSON.parse(
                                JSON.stringify(formik.errors.representatives[index])
                              ).email
                            : ''
                          : ''
                      }
                      className="bg-[#FFFFFF8C]"
                    />
                    <TextField
                      name={`representatives[${index}].position`}
                      type="text"
                      value={rep.position}
                      onChange={formik.handleChange}
                      onBlur={formik.handleChange}
                      label="Representative Position"
                      error={
                        formik.touched.representatives
                          ? formik.errors.representatives?.[index] &&
                            formik.errors.representatives.length > 0
                            ? JSON.parse(
                                JSON.stringify(formik.errors.representatives[index])
                              ).position
                            : ''
                          : ''
                      }
                      className="bg-[#FFFFFF8C]"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div
              tabIndex={0}
              className="flex items-center mt-8"
              role="button"
              onClick={addMoreRepresentative}
            >
              <div className="flex items-center gap-1">
                <Icon name={Icons.Add} />
                <span className="text-sm font-medium text-[#000000]">
                  Add another representative
                </span>
              </div>
              <div className="ml-5 line" style={{ width: '81.3%' }} />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <Button
              type="submit"
              variant="primary"
              size="md"
              label="Save & Continue"
              className="mt-12"
              loading={formik.isSubmitting || isSubmitting}
              disabled={formik.isSubmitting || isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
