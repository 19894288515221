import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  addCard,
  deleteCard,
  getBillingHistory,
  getCards,
  getCurrentPlan,
  getPlans,
  markCardAsDefault,
  subscribePlan,
} from '../../service/subscription';
import { useAppDispatch } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../thunks/account-thunk';

export const useGetCards = ({ businessId }: { businessId: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(
    ['cards'],
    async () => {
      const cards = await getCards(businessId);
      return cards;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!businessId,
      onError: (error: Error) => {
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useDeleteCard = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({ businessId, cardId }: { cardId: string; businessId: string }): Promise<void> => {
      return deleteCard(businessId, cardId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['delete-card']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useMarkCardAsDefault = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({ businessId, cardId }: { cardId: string; businessId: string }): Promise<void> => {
      return markCardAsDefault(businessId, cardId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['mark-card-as-default']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useGetPlans = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useQuery(
    ['plans'],
    async () => {
      const cards = await getPlans();
      return cards;
    },
    {
      refetchOnWindowFocus: false,
      enabled: true,
      onError: (error: Error) => {
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useGetCurrentPlan = ({ businessId }: { businessId: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(
    ['current-plan'],
    async () => {
      const cards = await getCurrentPlan(businessId);
      return cards;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!businessId,
      onError: (error: Error) => {
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useGetBillingHistory = ({ businessId }: { businessId: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useQuery(
    ['billing-history'],
    async () => {
      const cards = await getBillingHistory(businessId);
      return cards;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!businessId,
      onError: (error: Error) => {
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const usePlanSubscription = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      reference,
      seat,
    }: {
      reference: string;
      businessId: string;
      seat: number;
    }): Promise<void> => {
      return subscribePlan(businessId, reference, seat);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['mark-card-as-default']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};

export const useAddCard = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      businessId,
      reference,
    }: {
      reference: string;
      businessId: string;
    }): Promise<void> => {
      return addCard(businessId, reference);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['add-card']);
      },
      onError: (error: Error) => {
        toast.error(error.message);
        if (error.message === 'Session expired please login again') {
          dispatch(logout());
          navigate('/auth/login');
        }
      },
    }
  );
};
