import Button from '../Form/Button';
import { Icon, Icons } from '../Icon';
import { InfoModal } from './InfoModal';

type Props = {
  title?: string;
  content: string;
  actionText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel: () => void;
  isSubmitting?: boolean;
  cancelClassName?: string;
  actionClassName?: string;
};

export default function DeleteModal({
  title,
  content,
  actionText,
  cancelText,
  onConfirm,
  onCancel,
  isSubmitting,
  cancelClassName,
  actionClassName,
}: Props) {
  return (
    <InfoModal width="w-[25%]" className="h-fit " onClose={onCancel}>
      <div className="rounded-[20px] bg-white font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col items-center justify-between">
          <Icon name={Icons.DeleteCircle} />
          <div className="max-w-xs p-5 text-center">
            <p className="text-xl font-medium font-inter">{title}</p>
            <p className="my-3 text-xs font-medium font-inter">{content}</p>
          </div>
          <div className="flex items-center justify-center gap-3 my-3 mt-10">
            <Button
              variant="custom"
              size="custom"
              className={cancelClassName}
              label={cancelText || 'No'}
              onClick={onCancel}
              disabled={isSubmitting}
              loading={isSubmitting}
            ></Button>
            <Button
              variant="custom"
              size="custom"
              className={actionClassName}
              label={actionText || 'Yes'}
              onClick={onConfirm}
              disabled={isSubmitting}
              loading={isSubmitting}
            ></Button>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
