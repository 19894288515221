import React, { useState } from 'react';
import clsx from 'clsx';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

interface TextFieldProps {
  type?: 'text' | 'email' | 'number' | 'password' | 'date';
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  value: string | number | null;
  label?: string;
  placeholder?: string;
  name: string;
  disabled?: boolean;
  error?: string;
  showPasswordToggler?: boolean;
  className?: any;
  step?: any;
  onWheel?: (e: any) => void;
  min?: number;
  indicator?: string;
}

const TextField: React.FC<TextFieldProps> = ({
  type = 'text',
  onChange,
  onBlur,
  value,
  label,
  placeholder,
  name,
  disabled = false,
  error,
  showPasswordToggler,
  className,
  step,
  onWheel,
  min,
  indicator,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="relative">
      {label && (
        <label className="font-medium font-inter text-sm text-[#000000]">{label}</label>
      )}
      <div className="relative mb-5">
        <input
          className={clsx({
            'form-input p-3 bg-gray-50 text-sm font-inter font-normal placeholder:text-white-dark rounded-lg border border-[#0000001A]':
              true,
            'bg-red-50 border border-red-500 text-red-900 placeholder:text-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500':
              error,
            [className]: className,
          })}
          placeholder={placeholder}
          value={value ?? ''}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          type={showPassword ? 'text' : type}
          autoComplete="false"
          step={step}
          onWheel={onWheel}
          min={min}
        />
        {showPasswordToggler && (
          <div
            role="button"
            tabIndex={-1}
            onKeyDown={() => setShowPassword((prevState) => !prevState)}
            onClick={() => setShowPassword((prevState) => !prevState)}
          >
            <span className="absolute -translate-y-1/2 end-4 top-1/2">
              {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </span>
          </div>
        )}
        {error && <p className="mt-2 text-xs text-red-600 capitalize">{error}</p>}
        {indicator === 'Strong' ? (
          <p className="mt-2 text-xs text-green-600 capitalize">
            Excellent! {indicator} password{' '}
          </p>
        ) : indicator === 'Medium' ? (
          <p className="mt-2 text-xs text-green-300 capitalize">
            Good! your password strength is {indicator}
          </p>
        ) : indicator === 'Weak' ? (
          <p className="mt-2 text-xs text-red-600 capitalize">Password is too {error}</p>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default TextField;
