import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import Button from '../../../Components/Form/Button';
import CustomSelect from '../../../Components/Form/CustomSelect';
import TextField from '../../../Components/Form/TextField';
import { Icon, Icons } from '../../../Components/Icon';
import { ContactDto, FormDto } from '../../../types/business.type';
import IndustryList from '../../../Data/Industries';
import StateData from '../../../Data/StateData';

type Props = {
  onSubmit: (values: ContactDto) => void;
  values: FormDto | null;
};

export default function Contact({ onSubmit, values }: Props) {
  const addresses = useMemo(() => {
    if (values?.companyAddresss?.length) {
      return values?.companyAddresss.map((ad) => ({
        address: ad.address ?? '',
        country: ad.country ?? '',
        state: ad.state ?? '',
        postalCode: ad.postalCode ?? '',
        addressType: ad.addressType ?? '',
      }));
    } else {
      return [
        {
          address: '',
          country: '',
          state: '',
          postalCode: '',
          addressType: '',
        },
      ];
    }
  }, [values?.companyAddresss]);

  const formik = useFormik({
    initialValues: {
      type: 'buyer_supplier',
      name: values?.name ?? '',
      email: values?.email ?? '',
      phone: values?.phone ?? '',
      rcNumber: values?.rcNumber ?? '',
      industryType: values?.industryType ?? '',
      companySize: values?.companySize ?? '',
      companyAddresss: addresses,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Company name is required').nullable(),
      email: Yup.string()
        .email('Company Email')
        .required('Company email is required')
        .nullable(),
      phone: Yup.string().required('Phone number is required').nullable(),
      rcNumber: Yup.string().required('RC number is required').nullable(),
      industryType: Yup.string().required('Industry type is required').nullable(),
      companySize: Yup.string().required('Company size is required').nullable(),
      companyAddresss: Yup.array().of(
        Yup.object().shape({
          address: Yup.string().required('Company address is required').nullable(),
          country: Yup.string().required('Country is required').nullable(),
          state: Yup.string().required('City is required').nullable(),
          postalCode: Yup.string().required('Postal code is required').nullable(),
          addressType: Yup.string().required('Address type is required').nullable(),
        })
      ),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const addMoreAddress = () => {
    formik.values.companyAddresss.push({
      address: '',
      country: '',
      state: '',
      postalCode: '',
      addressType: '',
    });
    formik.setFieldValue('companyAddresss', [...formik.values.companyAddresss]);
  };

  const removeAddress = (index: any) => {
    const updatedAddresses = [...formik.values.companyAddresss];
    updatedAddresses.splice(index, 1);
    formik.setFieldValue('companyAddresss', updatedAddresses);
  };

  return (
    <div>
      <div className="border-b border-[#0000000D] pb-2">
        <h2 className="text-xl text-[#263238] font-semibold">Company Contact</h2>
        <p className="text-base font-normal font-inter text-[#00000066]">
          Please enter company contact below
        </p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-wrap items-center justify-between gap-4 mt-10">
          <div className="grid flex-1 grid-cols-1 gap-x-14 gap-y-2 sm:grid-cols-2">
            <TextField
              name="name"
              type="text"
              value={formik.values.name}
              onChange={(event) => {
                const newValue = event.target.value.replace(/[^a-zA-Z]/g, '');
                formik.handleChange(event);
                formik.setFieldValue('name', newValue);
              }}
              onBlur={formik.handleChange}
              label="Company Name"
              error={formik.touched.name ? formik.errors.name : ''}
              className="bg-[#FFFFFF8C]"
            />
            <TextField
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleChange}
              label="Company Email"
              error={formik.touched.email ? formik.errors.email : ''}
              className="bg-[#FFFFFF8C]"
            />
            <TextField
              name="phone"
              type="text"
              value={formik.values.phone}
              onChange={(event) => {
                const newValue = event.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
                formik.handleChange(event); // Update formik state
                formik.setFieldValue('phone', newValue); // Set validated value back to formik
              }}
              onBlur={formik.handleChange}
              label="Phone Number"
              error={formik.touched.phone ? formik.errors.phone : ''}
              className="bg-[#FFFFFF8C]"
            />
            <TextField
              name="rcNumber"
              type="text"
              value={formik.values.rcNumber}
              onChange={(event) => {
                const newValue = event.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
                formik.handleChange(event); // Update formik state
                formik.setFieldValue('rcNumber', newValue); // Set validated value back to formik
              }}
              onBlur={formik.handleChange}
              label="RC Number"
              error={formik.touched.rcNumber ? formik.errors.rcNumber : ''}
              className="bg-[#FFFFFF8C]"
            />
            <CustomSelect
              options={IndustryList.map((industry) => ({
                value: industry,
                label: industry,
              }))}
              selectedOption={{
                value: formik.values.industryType,
                label: formik.values.industryType,
              }}
              handleOptionChange={(option) => {
                formik.setFieldValue('industryType', option?.value);
              }}
              label="Industry Type"
              name="industryType"
              isSearchable
              className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
              error={formik.touched.industryType ? formik.errors.industryType : ''}
            />
            <CustomSelect
              options={[
                { value: '1-100', label: '1-100' },
                { value: '101 - 200', label: '101 - 200' },
                { value: '201 - 500', label: '201 - 500' },
                { value: '501 - 1000', label: '501 - 1000' },
                { value: '> 1000', label: '> 1000' },
              ]}
              selectedOption={{
                value: formik.values.companySize,
                label: formik.values.companySize,
              }}
              handleOptionChange={(option) => {
                formik.setFieldValue('companySize', option?.value);
              }}
              label="Company Size"
              name="companySize"
              isSearchable
              className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
              error={formik.touched.companySize ? formik.errors.companySize : ''}
            />
          </div>
        </div>

        <div className="w-full mt-20">
          <div className="border-b border-[#0000000D] pb-2">
            <h2 className="text-xl text-[#263238] font-semibold">Company Address</h2>
            <p className="text-base font-normal font-inter text-[#00000066]">
              Please enter company address below
            </p>
          </div>
          <div className="gap-4 mt-10">
            {formik.values.companyAddresss.map((ad, index) => (
              <div key={index} className="mt-10 lg:mt-4 md:mt-4">
                <div className="flex items-center justify-end">
                  <Button
                    onClick={() => removeAddress(index)}
                    type="button"
                    variant="outline"
                    size="md"
                    label="Remove Address"
                    className="mt-12 text-white"
                  />
                </div>

                <div className="grid flex-1 grid-cols-1 gap-x-14 gap-y-2 sm:grid-cols-2">
                  <TextField
                    name={`companyAddresss[${index}].address`}
                    type="text"
                    value={ad.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    label="Company Address"
                    error={
                      formik.touched.companyAddresss
                        ? formik.errors.companyAddresss?.[index] &&
                          formik.errors.companyAddresss.length > 0
                          ? JSON.parse(
                              JSON.stringify(formik.errors.companyAddresss[index])
                            ).address
                          : ''
                        : ''
                    }
                    className="bg-[#FFFFFF8C]"
                  />
                  <CustomSelect
                    options={[{ value: 'Nigeria', label: 'Nigeria' }]}
                    selectedOption={{
                      value: ad.country,
                      label: ad.country,
                    }}
                    handleOptionChange={(option) => {
                      formik.setFieldValue(
                        `companyAddresss[${index}].country`,
                        option?.value
                      );
                    }}
                    label="Country"
                    name={`companyAddresss[${index}].country`}
                    isSearchable
                    className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                    error={
                      formik.touched.companyAddresss
                        ? formik.errors.companyAddresss?.[index] &&
                          formik.errors.companyAddresss.length > 0
                          ? JSON.parse(
                              JSON.stringify(formik.errors.companyAddresss[index])
                            ).country
                          : ''
                        : ''
                    }
                  />
                </div>
                <div className="grid grid-cols-1 mb-5 gap-x-14 gap-y-2 md:grid-cols-3 lg:grid-cols-4">
                  <div>
                    <CustomSelect
                      options={StateData.map((st) => ({
                        value: st.state.name,
                        label: st.state.name,
                      }))}
                      selectedOption={{
                        value: ad.state,
                        label: ad.state,
                      }}
                      handleOptionChange={(option) => {
                        formik.setFieldValue(
                          `companyAddresss[${index}].state`,
                          option?.value
                        );
                      }}
                      label="State"
                      name={`companyAddresss[${index}].state`}
                      isSearchable
                      className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                      error={
                        formik.touched.companyAddresss
                          ? formik.errors.companyAddresss?.[index] &&
                            formik.errors.companyAddresss.length > 0
                            ? JSON.parse(
                                JSON.stringify(formik.errors.companyAddresss[index])
                              ).state
                            : ''
                          : ''
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      name={`companyAddresss[${index}].postalCode`}
                      type="text"
                      value={ad.postalCode}
                      onChange={(event) => {
                        const newValue = event.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
                        formik.handleChange(event); // Update formik state
                        formik.setFieldValue(
                          `companyAddresss[${index}].postalCode`,
                          newValue
                        ); // Set validated value back to formik
                      }}
                      onBlur={formik.handleChange}
                      label="Postal Code"
                      error={
                        formik.touched.companyAddresss
                          ? formik.errors.companyAddresss?.[index] &&
                            formik.errors.companyAddresss.length > 0
                            ? JSON.parse(
                                JSON.stringify(formik.errors.companyAddresss[index])
                              ).postalCode
                            : ''
                          : ''
                      }
                      className="bg-[#FFFFFF8C]"
                    />
                  </div>
                  <div className="md:col-span-2">
                    <CustomSelect
                      options={[
                        {
                          value: 'registeredAddress',
                          label: 'Registered Address',
                        },
                        {
                          value: 'operatingAddress',
                          label: 'Operating Address',
                        },
                      ]}
                      selectedOption={{
                        value: ad.addressType,
                        label: ad.addressType,
                      }}
                      handleOptionChange={(option) => {
                        formik.setFieldValue(
                          `companyAddresss[${index}].addressType`,
                          option?.label
                        );
                      }}
                      label="Address Type"
                      name={`companyAddresss[${index}].addressType`}
                      isSearchable
                      className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                      error={
                        formik.touched.companyAddresss
                          ? formik.errors.companyAddresss?.[index] &&
                            formik.errors.companyAddresss.length > 0
                            ? JSON.parse(
                                JSON.stringify(formik.errors.companyAddresss[index])
                              ).addressType
                            : ''
                          : ''
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
            <div
              role="button"
              className="flex items-center"
              tabIndex={0}
              onClick={addMoreAddress}
            >
              <div className="flex items-center gap-1">
                <Icon name={Icons.Add} />
                <span className="text-sm font-medium text-[#000000]">
                  Add {formik?.values?.companyAddresss?.length > 0 ? 'another' : ''}{' '}
                  address{' '}
                </span>
              </div>
              <div className="ml-5 line" style={{ width: '83%' }} />
            </div>

            <div className="flex items-end justify-end">
              <Button
                type="submit"
                variant="primary"
                size="md"
                label="Save & Continue"
                className="mt-12"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
