import React from 'react';
import Receivable from './Receivable';
import Payable from './Payable';
import Vendors from './Vendors';
import { useGetApproval } from '../../../../../hooks/queries-and-mutations/settings';
import { useAppSelector } from '../../../../../store/hooks';
import { selectBusiness } from '../../../../../selectors/business-selector';
import { groupDataByLevel } from '../../../../../utils';

export default function Approval() {
  const { docs } = useAppSelector(selectBusiness);
  const { data: approvals, refetch } = useGetApproval({
    businessId: docs[0]?.businessID?._id,
  });
  const groupedData = groupDataByLevel(approvals?.docs ?? []);

  return (
    <div className="w-full">
      <div className="border-b pl-9 py-5">
        <p className="font-semibold text-2xl font-inter text-black">Approval</p>
      </div>
      <div className="p-8 space-y-7 mb-52">
        <Receivable
          businessId={docs[0]?.businessID?._id}
          receivable={groupedData.receivable}
          refetch={() => refetch()}
        />
        <Payable
          businessId={docs[0]?.businessID?._id}
          payable={groupedData.payable}
          refetch={() => refetch()}
        />
        <Vendors
          businessId={docs[0]?.businessID?._id}
          vendor={groupedData.vendor}
          refetch={() => refetch()}
        />
      </div>
    </div>
  );
}
