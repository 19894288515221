import React, { useState, useContext, useEffect, useRef } from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { axiosCalls } from '../Api/_axios';

const AppContext = React.createContext<any>({});

const AppProvider = ({ children }: any) => {
  const authToken = sessionStorage.getItem('accessToken');
  const baseUrl = process.env.REACT_APP_API_URL;
  const [sideBar, setSideBar] = useState<any>('false');
  const toggleSidebar = () => {
    setSideBar(!sideBar);
  };
  const [businessData, setBusinessData] = useState({});
  const [businessLoading, setBusinessLoading] = useState(true);
  const divRef = useRef<HTMLDivElement>(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>('');
  const handleScrollToTop = () => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  };
  const fetchBusinessData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/business/user/fetch?limit=15`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res.status === 200) {
        setBusinessData(res.data.data.docs[0]);
        if (!sessionStorage.getItem('userPermissions')) {
          try {
            const Permissions = await axiosCalls(
              `role/${res.data.data.docs[0].businessID._id}/fetch-one`,
              'GET',
              null,
              authToken
            );
            sessionStorage.setItem(
              'userPermissions',
              JSON.stringify(Permissions.data.permissions)
            );
          } catch (error) {
            const err = error as AxiosError<Error>;
            toast.error(
              err.message ?? 'Sorry, An error has occurred while trying to fetch business'
            );
          }
        }
        setBusinessLoading(false);
      }
    } catch (error) {
      const err = error as AxiosError<Error>;
      if (err.response && err.response.status === 403) {
        toast.warn('Session Expired!. Please login again', {
          position: toast.POSITION.TOP_RIGHT,
        });
        sessionStorage.clear();
        setTimeout(() => {
          window.location.replace('/login');
        }, 2000);
      }
      toast.error('Sorry!, An error has occurred while fetching business data');
    }
  };
  useEffect(() => {
    if (authToken) {
      fetchBusinessData();
    }
  }, []);
  return (
    <AppContext.Provider
      value={{
        sideBar,
        toggleSidebar,
        setSideBar,
        businessData,
        businessLoading,
        divRef,
        handleScrollToTop,
        setSelectedInvoiceId,
        selectedInvoiceId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
