import { useState, useRef, useEffect } from 'react';
import { ReactComponent as Checked } from '../../Assests/checked.svg';
import { ReactComponent as Circle } from '../../Assests/circle.svg';
import { ReactComponent as CircleD } from '../../Assests/circleD.svg';
import { ReactComponent as Dotc } from '../../Assests/dotsC.svg';
import { ReactComponent as Line } from '../../Assests/line.svg';
import { ReactComponent as LineD } from '../../Assests/lineD.svg';

import { FaBars, FaCheckCircle } from 'react-icons/fa';
import Logo from '../../Assests/2.png';
import './Auth.scss';
import Docs from './components/Docs';
import { useNavigate, useParams } from 'react-router-dom';
import Address from './components/Address';
import ContactComp from './components/ContactComp';
import Preview from './components/Preview';
import ButtonDir from './components/ButtonDir';
import Directors from './components/Directors';
import RepsComp from './components/Reps';
import { axiosCalls } from '../../Api/_axios';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

interface Props {
  notify: (type: string, message: string) => void;
}
interface BlobRecord {
  id: number;
  data: Blob;
}
const dbName = 'paytton';
const storeName = 'kybDocs';

function Final({ notify }: Props) {
  const token = sessionStorage.getItem('accessToken');
  const { stage } = useParams();
  const myComponentRef = useRef<any>();
  const navigate = useNavigate();
  const [showSideBar, setShowSideBar] = useState<any>('false');
  const [formState, setFormState] = useState<any>({
    type: '',
    email: '',
    name: '',
    phone: '',
    industryType: '',
    companySize: '',
    rcNumber: '',
    bankInfo: {
      bankName: '',
      accountName: '',
      accountNumber: '',
      bankCode: '',
    },
  });
  const [formLoading, setFormLoading] = useState(false);
  const [addressFields, setaddressFields] = useState<any>([
    {
      address: '',
      country: '',
      state: '',
      postalCode: '',
      addressType: '',
    },
  ]);
  const [directoss, setDirectoss] = useState<any>([
    {
      type: '',
      fullName: '',
      number: '',
    },
  ]);
  const [reps, setReps] = useState<any>([
    {
      firstName: '',
      lastName: '',
      email: '',
      position: '',
    },
  ]);
  const [emptyFields, setEmptyFields] = useState<any>([]);
  //This is the part for the Documents
  const [businessDoc, setBusinessDoc] = useState<File | null>(null);
  const [utilityBillDoc, setUtilityBillDoc] = useState<File | null>(null);
  const [identificationDoc, setIdentificationDoc] = useState<File | null>(null);
  const [licensesDoc, setLicensesDoc] = useState<File | null>(null);
  const [businessDocName, setBusinessDocName] = useState<string>('No file selected yet');
  const [utilityBillDocName, setUtilityBillDocName] = useState('No file selected yet');
  const [identificationDocName, setIdentificationDocName] =
    useState('No file selected yet');
  const [licensesDocName, setLicensesDocName] = useState<string>('No file selected yet');
  //!convert files for storing and fetching
  function openDatabase(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName);

      request.onupgradeneeded = (event: any) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, {
            keyPath: 'id',
            autoIncrement: true,
          });
        }
      };

      request.onsuccess = (event: any) => {
        const db = event.target.result;
        resolve(db);
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }
  async function storeBlobs(blobs: Blob[]): Promise<void> {
    const db = await openDatabase();

    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);

    for (let i = 0; i < blobs.length; i++) {
      const record: BlobRecord = {
        data: blobs[i],
        id: i + 1,
      }; // Use a numeric index as a unique key
      store.add(record);
    }
    await new Promise<void>((resolve) => {
      transaction.oncomplete = () => {
        resolve();
      };
    });

    db.close();
  }
  async function retrieveBlob(blobId: number): Promise<Blob | undefined> {
    const db = await openDatabase();
    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);

    return new Promise<Blob | undefined>((resolve, reject) => {
      const request = store.get(blobId);

      request.onsuccess = () => {
        if (request.result) {
          resolve(request.result.data);
        } else {
          resolve(undefined); // Blob not found
        }
      };

      request.onerror = () => {
        reject(request.error);
      };
    });
  }
  //The buttons controll-----------------------------------
  const forwardBtn = () => {
    if (stage === 'contact') {
      const { email, name, phone, industryType, companySize, rcNumber } = formState;
      const newEmptyFields = [];
      if (name === '') {
        newEmptyFields.push('Name');
      }
      if (email === '') {
        newEmptyFields.push('Email');
      }
      if (phone === '') {
        newEmptyFields.push('Phone');
      }
      if (rcNumber === '') {
        newEmptyFields.push('RC Number');
      }
      if (industryType === '') {
        newEmptyFields.push('Industry Type');
      }
      if (companySize === '') {
        newEmptyFields.push('Company Size');
      }

      if (
        addressFields.length < 1 ||
        addressFields.some(
          (address: any) =>
            address.address.length < 1 ||
            address.country.length < 1 ||
            address.state.length < 1 ||
            address.addressType.length < 1 ||
            address.postalCode.length < 1
        )
      ) {
        newEmptyFields.push('Address');
      }

      if (newEmptyFields.length > 0) {
        setEmptyFields(newEmptyFields);

        //  window.scrollTo(0, firstEmptyField.offsetTop)
        notify('warn', `${newEmptyFields[0]} is empty.`);
      } else {
        localStorage.setItem('formState', JSON.stringify(formState));
        localStorage.setItem('address', JSON.stringify(addressFields));
        navigate('/create-business/kyb');
        setEmptyFields([]);
        myComponentRef.current.scrollTop = 0;
      }
    } else if (stage === 'kyb') {
      myComponentRef.current.scrollTop = 0;
      const newEmptyFields = [];
      if (businessDoc === null) {
        newEmptyFields.push('Business Document');
      }
      if (utilityBillDoc === null) {
        newEmptyFields.push('Utility bill Document');
      }
      if (identificationDoc === null) {
        newEmptyFields.push('Director Identification Document');
      }
      if (licensesDoc === null) {
        newEmptyFields.push('Licence Document');
      }
      if (formState.bankInfo.bankName === '') {
        newEmptyFields.push('Bank Name');
      }
      if (formState.bankInfo.accountName === '') {
        newEmptyFields.push('Account Name');
      }
      if (formState.bankInfo.accountNumber === '') {
        newEmptyFields.push('Account Number');
      }
      // if (
      //   formState.bankInfo.accountName.toLocaleLowerCase() !==
      //   formState.name.toLocaleLowerCase()
      // ) {
      //   newEmptyFields.push('Account name and business name must match')
      // }
      if (
        directoss.length < 1 ||
        directoss.some(
          (directox: any) => directox.fullName.length < 1 || directox.number.length < 1
        )
      ) {
        newEmptyFields.push('Fill at least one Director Information completely');
      }
      if (
        reps.length < 1 ||
        reps.some(
          (rep: any) =>
            rep.firstName.length < 1 ||
            rep.lastName.length < 1 ||
            rep.email.length < 1 ||
            rep.position.length < 1
        )
      ) {
        newEmptyFields.push('Fill at least one Rep Information completely');
      }

      if (newEmptyFields.length > 0) {
        setEmptyFields(newEmptyFields);
        notify('warn', `${newEmptyFields[0]} is empty.`);
      } else {
        const blobsToStore: any = [
          businessDoc,
          utilityBillDoc,
          identificationDoc,
          licensesDoc,
        ];
        storeBlobs(blobsToStore);
        localStorage.setItem('formState', JSON.stringify(formState));
        localStorage.setItem('directoss', JSON.stringify(directoss));
        localStorage.setItem('reps', JSON.stringify(reps));
        navigate('/create-business/preview');
        setEmptyFields([]);
      }
    }
  };
  const previousBtn = () => {
    if (stage === 'preview') {
      myComponentRef.current.scrollTop = 0;
      navigate('/create-business/kyb');
    } else if (stage === 'kyb') {
      myComponentRef.current.scrollTop = 0;
      navigate('/create-business/contact');
    }
  };

  // For the Submit
  const handleSubmit = async () => {
    setFormLoading(true);
    const formData = new FormData();
    formData.append('type', 'buyer_supplier');
    formData.append('email', formState.email.toLocaleLowerCase());
    formData.append('name', formState.name);
    formData.append('phone', formState.phone);
    formData.append('industryType', formState.industryType);
    formData.append('companySize', formState.companySize);
    formData.append('rcNumber', formState.rcNumber);
    const files = [businessDoc!, utilityBillDoc!, identificationDoc!, licensesDoc!];
    const banks = [formState.bankInfo];
    files.forEach((file) => {
      formData.append('kybDocument', file);
    });
    for (let i = 0; i < addressFields.length; i++) {
      const addressObj = addressFields[i];
      formData.append('addresses', JSON.stringify(addressObj));
    }
    for (let i = 0; i < directoss.length; i++) {
      const addressObj = directoss[i];
      formData.append('directorsDetails', JSON.stringify(addressObj));
    }
    for (let i = 0; i < reps.length; i++) {
      const addressObj = reps[i];
      formData.append('repsDetails', JSON.stringify(addressObj));
    }
    for (let i = 0; i < banks.length; i++) {
      const addressObj = banks[i];
      formData.append('bankInfo', JSON.stringify(addressObj));
    }

    fetch(`${process.env.REACT_APP_API_URL}/business/create`, {
      method: 'post',
      headers: {
        // 'content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: formData,
      redirect: 'follow',
    })
      .then((res) => {
        if (res.status === 201) {
          notify('success', 'Business Registration Successful');
          setFormLoading(false);
          setTimeout(() => {
            localStorage.clear();
            indexedDB.deleteDatabase(dbName);
            window.location.replace('/user/vendors');
          }, 1000);
        } else if (res.status === 403) {
          res.json().then((data) => {
            if (
              data.errors.includes('verify your account before performing this action')
            ) {
              notify('error', 'Verify your account before performing this action');
            } else {
              notify('error', 'Session expired please login again');
              sessionStorage.clear();
            }
          });
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        } else {
          res.json().then((data) => {
            notify('error', data.errors.join('. '));
            setFormLoading(false);
          });
        }
      })
      .catch(() => {
        notify('error', 'An error occured please try again');
        setFormLoading(false);
      });
  };

  // For Fetching Banks
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setLoading] = useState(true);
  const [bankList, setBankList] = useState<any>([]);
  const [errorBank, setErrorBank] = useState<any>(null);
  const [bankError, setbankError] = useState('');
  const [bankNameLoading, setBankNameLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axiosCalls(`business/banks/fetch`, 'GET', null, token);
        setLoading(false);
        setBankList(result.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    const storedFormState = localStorage.getItem('formState');
    const storedAddress = localStorage.getItem('address');
    const storedDirectoss = localStorage.getItem('directoss');
    const storedReps = localStorage.getItem('reps');
    if (storedFormState !== null) {
      setFormState(JSON.parse(storedFormState));
    }
    if (storedAddress !== null) {
      setaddressFields(JSON.parse(storedAddress));
    }
    if (storedDirectoss !== null) {
      setDirectoss(JSON.parse(storedDirectoss));
    }
    if (storedReps !== null) {
      setReps(JSON.parse(storedReps));
    }
    retrieveBlob(1).then((retrievedBlob: any) => {
      if (retrievedBlob) {
        setBusinessDoc(retrievedBlob);
        // Use the retrievedBlob
      }
    });
    retrieveBlob(2).then((retrievedBlob: any) => {
      if (retrievedBlob) {
        setUtilityBillDoc(retrievedBlob);
        // Use the retrievedBlob
      }
    });
    retrieveBlob(3).then((retrievedBlob: any) => {
      if (retrievedBlob) {
        setIdentificationDoc(retrievedBlob);
        // Use the retrievedBlob
      }
    });
    retrieveBlob(4).then((retrievedBlob: any) => {
      if (retrievedBlob) {
        setLicensesDoc(retrievedBlob);
        // Use the retrievedBlob
      }
    });
  }, []);
  const fetchBankname = async (id: string) => {
    setBankNameLoading(true);
    const findBankName = bankList.find(
      (item: any) => item.code === formState.bankInfo.bankCode
    );
    setFormState((prevState: any) => ({
      ...prevState,
      bankInfo: {
        ...prevState.bankInfo,
        bankName: findBankName.name,
      },
    }));
    const data = {
      accountNumber: id,
      bankCode: formState.bankInfo.bankCode,
    };
    try {
      const result = await axiosCalls(
        `/business/banks/account-name`,
        'POST',
        data,
        token
      );
      if (result.success) {
        setErrorBank(false);
        setFormState((prevState: any) => ({
          ...prevState,
          bankInfo: {
            ...prevState.bankInfo,
            accountName: result.data.acccountName,
          },
        }));
      } else {
        setbankError(result.er.errors.join(','));
        setErrorBank(true);
        setBankNameLoading(false);
      }
    } catch (error) {
      const err = error as AxiosError<Error>;
      toast.error(
        err.message ?? 'Sorry!, An error has occurred while fetching account name'
      );
    }
  };

  return (
    <div className="final">
      {/* <IdleTimer /> */}
      <div className={showSideBar ? 'left' : 'left show'}>
        <div className="top">
          <img
            src={Logo}
            alt=""
            onClick={() => {
              if (!showSideBar) {
                setShowSideBar('false');
              }
            }}
          />
        </div>
        <div className="drop">
          <div className="card">
            <div className="le">
              {stage === 'contact' && (
                <>
                  <Dotc />
                  <Line />
                </>
              )}
              {stage !== 'contact' && (
                <>
                  <Checked />
                  <Line />
                </>
              )}
            </div>
            <div className="ri">
              <h2>Contact info</h2>
              <p>Input company's contact Information</p>
            </div>
          </div>
          <div className="card">
            <div className="le">
              {stage === 'contact' && (
                <>
                  <Circle />
                  <LineD />
                </>
              )}
              {stage === 'kyb' && (
                <>
                  <Dotc />
                  <Line />
                </>
              )}
              {stage === 'preview' ? (
                <>
                  <Checked />
                  <Line />
                </>
              ) : (
                ''
              )}
            </div>
            <div className="ri">
              <h2>KYB</h2>
              <p>Input some documents and details for us to verify the company.</p>
            </div>
          </div>
          <div className="card">
            <div className="le">
              {stage === 'contact' ? (
                <>
                  <CircleD />
                </>
              ) : (
                ''
              )}
              {stage === 'kyb' && (
                <>
                  <Circle />
                </>
              )}
              {stage === 'preview' && (
                <>
                  <Dotc />
                </>
              )}
            </div>
            <div className="ri">
              <h2>Preview</h2>
              <p>Preview informations to confirm that they are correct.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="right" ref={myComponentRef}>
        <div className="cover">
          <div className="bars">
            <FaBars onClick={() => setShowSideBar(!showSideBar)} />
          </div>
          {stage === 'contact' && (
            <div
              role="button"
              tabIndex={-1}
              className="top"
              onClick={() => {
                if (!showSideBar) {
                  setShowSideBar('false');
                }
              }}
            >
              <h2>Welcome</h2>
            </div>
          )}
          <div
            role="button"
            tabIndex={-1}
            className="form"
            onClick={() => {
              if (!showSideBar) {
                setShowSideBar('false');
              }
            }}
          >
            {stage === 'contact' && (
              <>
                <ContactComp
                  setFormState={setFormState}
                  formState={formState}
                  emptyFields={emptyFields}
                />
                <Address
                  addressFields={addressFields}
                  setaddressFields={setaddressFields}
                  emptyFields={emptyFields}
                />
              </>
            )}
            {stage === 'kyb' && (
              <>
                <Docs
                  businessDoc={businessDoc}
                  licensesDoc={licensesDoc}
                  utilityBillDoc={utilityBillDoc}
                  identificationDoc={identificationDoc}
                  setBusinessDoc={setBusinessDoc}
                  setUtilityBillDoc={setUtilityBillDoc}
                  setIdentificationDoc={setIdentificationDoc}
                  setLicensesDoc={setLicensesDoc}
                  businessDocName={businessDocName}
                  utilityBillDocName={utilityBillDocName}
                  setBusinessDocName={setBusinessDocName}
                  setLicensesDocName={setLicensesDocName}
                  identificationDocName={identificationDocName}
                  licensesDocName={licensesDocName}
                  setUtilityBillDocName={setUtilityBillDocName}
                  setIdentificationDocName={setIdentificationDocName}
                  emptyFields={emptyFields}
                />
                <Directors
                  directoss={directoss}
                  setDirectoss={setDirectoss}
                  emptyFields={emptyFields}
                />
                <RepsComp reps={reps} setReps={setReps} emptyFields={emptyFields} />
                <div className="form-body">
                  <div className="tag">
                    <p>Bank Account details</p>
                  </div>
                  <div className="card">
                    <p>Bank Name</p>
                    <select
                      name=""
                      className={
                        emptyFields.includes('Bank Name') &&
                        formState.bankInfo.bankName === ''
                          ? 'active'
                          : ''
                      }
                      onChange={(e) => {
                        setFormState((prevState: any) => ({
                          ...prevState,
                          bankInfo: {
                            ...prevState.bankInfo,
                            bankCode: e.target.value,
                            accountName: '',
                          },
                        }));
                        setBankNameLoading(false);
                        setErrorBank(null);
                      }}
                    >
                      {formState.bankInfo.bankCode.length > 0 ? (
                        <option value={formState.bankInfo.bankName}>
                          {formState.bankInfo.bankName}
                        </option>
                      ) : (
                        <option value="">-Select-</option>
                      )}
                      {bankList.map((item: any) => (
                        <option value={item.code} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="card">
                    <p>Account Number</p>
                    <input
                      type="tel"
                      name=""
                      className={
                        emptyFields.includes('Account Number') &&
                        formState.bankInfo.accountNumber === ''
                          ? 'active'
                          : ''
                      }
                      onChange={(e) => {
                        setFormState((prevState: any) => ({
                          ...prevState,
                          bankInfo: {
                            ...prevState.bankInfo,
                            accountNumber: e.target.value,
                          },
                        }));
                        if (e.target.value.length === 10) {
                          fetchBankname(e.target.value);
                        }
                      }}
                      disabled={bankNameLoading}
                      defaultValue={formState.bankInfo.accountNumber}
                    />
                    {errorBank ? (
                      <h6 style={{ color: 'red' }} className="error">
                        {bankError}
                      </h6>
                    ) : (
                      errorBank === false && (
                        <FaCheckCircle className="check" style={{ color: 'green' }} />
                      )
                    )}
                  </div>
                  <div className="card">
                    <p>Account Name</p>
                    <input
                      type="text"
                      name=""
                      disabled
                      value={formState.bankInfo.accountName}
                    />
                  </div>
                </div>
              </>
            )}
            {stage === 'preview' && (
              <Preview
                businessDocName={businessDocName}
                utilityBillDocName={utilityBillDocName}
                identificationDocName={identificationDocName}
                licensesDocName={licensesDocName}
                addressFields={addressFields}
                formState={formState}
                directoss={directoss}
                reps={reps}
              />
            )}

            <ButtonDir
              stage={stage}
              previousBtn={previousBtn}
              forwardBtn={forwardBtn}
              formLoading={formLoading}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Final;
