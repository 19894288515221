import { useEffect, useState } from 'react';
import Button from '../../../../../Components/Form/Button';
import { Icon, Icons } from '../../../../../Components/Icon';
import EditCompliance from './EditCompliance';
import { useAppSelector } from '../../../../../store/hooks';
import { selectBusiness } from '../../../../../selectors/business-selector';
import { useUpdateBusiness } from '../../../../../hooks/queries-and-mutations/business';
import { FormDto } from '../../../../../types/business.type';
import { toast } from 'react-toastify';

export default function Compliance() {
  const [showEdit, setShowEdit] = useState(false);
  const [accordian, setAccordian] = useState({
    kyb: false,
    director: false,
    rep: false,
  });

  const { docs } = useAppSelector(selectBusiness);

  const { mutate, isSuccess, isError } = useUpdateBusiness();

  const handleSubmit = (forms: FormDto) => {
    let payload;
    if (forms.banks) {
      payload = {
        ...forms,
        companyAddresss: docs[0].businessID.addresses,
        type: docs[0].businessID.type,
        name: docs[0].businessID?.name ?? '',
        email: docs[0].businessID?.email ?? '',
        phone: docs[0].businessID?.phone ?? '',
        rcNumber: docs[0].businessID?.rcNumber ?? '',
        industryType: docs[0].businessID?.industryType ?? '',
        companySize: docs[0].businessID?.companySize ?? '',
      };
    } else {
      payload = {
        ...forms,
        banks: docs[0].businessID.bankInfo.map((bk) => ({
          ...bk,
          bank: { value: bk.bankCode, label: bk.bankName },
        })),
      };
    }

    const formData = new FormData();
    formData.append('type', 'buyer_supplier');
    formData.append('name', payload.name);
    formData.append('email', payload.email);
    formData.append('phone', payload.phone);
    formData.append('industryType', payload.industryType);
    formData.append('companySize', payload.companySize);
    formData.append('rcNumber', payload.rcNumber);

    const files = [
      forms.businessFile!,
      forms.utilityFile!,
      forms.identificationFile!,
      forms.licenseFile!,
    ];

    files.forEach((file) => {
      formData.append('kybDocument', file);
    });

    for (let i = 0; i < payload.companyAddresss.length; i++) {
      const addressObj = payload.companyAddresss[i];
      formData.append('addresses', JSON.stringify(addressObj));
    }

    // for (let i = 0; i < docs[0].businessID.directorsDetails.length; i++) {
    //   const addressObj = docs[0].businessID.directorsDetails[i]
    //   formData.append('directorsDetails', JSON.stringify(addressObj))
    // }

    for (let i = 0; i < docs[0].businessID.repsDetails.length; i++) {
      const addressObj = docs[0].businessID.repsDetails[i];
      formData.append('repsDetails', JSON.stringify(addressObj));
    }

    for (let i = 0; i < payload.banks.length; i++) {
      const bank = payload.banks[i];
      const bankObj = {
        ...bank,
        bankName: bank.bank.label,
        bankCode: bank.bank.value,
      };
      if ('bank' in bankObj) {
        delete (bankObj as { bank?: any }).bank;
      }
      formData.append('bankInfo', JSON.stringify(bankObj));
    }
    mutate({ payload: formData, businessId: docs[0].businessID._id });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setShowEdit(false);
      toast.success(
        'Business update request submitted successfully and is under review.'
      );
    }
  }, [isError, isSuccess]);

  const showName = (name: string) => {
    switch (name) {
      case 'BusinessDoc':
        return 'Business registration document';
      case 'Businessregistration':
        return 'Business registration document';
      case 'LicensesDoc':
        return 'Lincences document';
      case 'License':
        return 'Lincences document';
      case 'UtilityBillDoc':
        return ' Latest Utility bill';
      case 'Utilitybill':
        return ' Latest Utility bill';
      case 'IdentificationDoc':
        return 'Identification documents';
      case 'Proofofidentification':
        return 'Identification documents';
      default:
        break;
    }
  };

  const handleAccordian = (field: string, value: boolean) => {
    setAccordian((prevState) => ({ ...prevState, [field]: !value }));
  };

  return (
    <div className="w-full">
      <div className="py-5 border-b pl-9">
        <p className="text-2xl font-semibold text-black font-inter">Compliance</p>
      </div>
      {!showEdit && (
        <div className="p-8">
          <div className="pb-5 mb-8 border-b">
            <div className="flex items-center justify-between">
              <h2 className="text-xl text-[#263238] font-semibold">KYB Documents</h2>
              <button
                type="button"
                className={`${accordian.kyb ? 'rotate-180' : ''}`}
                onClick={() => handleAccordian('kyb', accordian.kyb)}
              >
                <Icon name={Icons.CaretDown} />
              </button>
            </div>
            {accordian.kyb && (
              <div className="grid flex-1 grid-cols-1 mt-10 gap-y-5 sm:grid-cols-2">
                {docs[0].businessID.kybDocument.map((item) => {
                  return (
                    <a
                      href={item.documentURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={item._id}
                    >
                      <div className="flex items-center gap-2">
                        <Icon name={Icons.Attachment} />
                        <p>{item.name}</p>
                        <span className="font-medium font-inter text-lg text-[#0353A4]">
                          {showName(item.name)}
                        </span>
                      </div>
                    </a>
                  );
                })}
                {!showEdit && (
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    label="Edit"
                    className="mt-5 bg-transparent border-[#0353A4] text-[#0353A4]"
                    icon={<Icon name={Icons.EditPencil} />}
                    iconPosition="left"
                    iconLabelSpacing={5}
                    onClick={() => setShowEdit(true)}
                  />
                )}
              </div>
            )}
          </div>
          {/* Director */}
          <div className="pb-5 mt-2 border-b">
            <div className="flex items-center justify-between">
              <h2 className="text-xl text-[#263238] font-semibold">
                Director's Information
              </h2>
              <button
                type="button"
                className={`${accordian.director ? 'rotate-180' : ''}`}
                onClick={() => handleAccordian('director', accordian.director)}
              >
                <Icon name={Icons.CaretDown} />
              </button>
            </div>
            {accordian.director && (
              <div>
                {(docs[0]?.businessID?.directorsDetails ?? []).map((dirs) => (
                  <div
                    className="grid flex-1 grid-cols-1 gap-y-5 sm:grid-cols-2 mt-10 border-b border-[#0000000D] pb-6"
                    key={dirs._id}
                  >
                    <div className="flex flex-col gap-2">
                      <p className="text-sm font-medium text-black/40">
                        Director Fullname
                      </p>
                      <span className="text-lg font-medium text-black font-inter">
                        {dirs.fullName}
                      </span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-sm font-medium text-black/40">ID Number</p>
                      <span className="text-lg font-medium text-black font-inter">
                        {dirs.number}
                      </span>
                    </div>
                    {/* <p className='text-red-300'>Backend need to work on the newly added info</p> */}
                    {/* //! <div className='flex items-center gap-2'>
                  <Icon name={Icons.Attachment} />
                  <span className='font-medium font-inter text-lg text-[#0353A4]'>Proofofidentification.pdf</span>
                </div> */}
                  </div>
                ))}
                {!showEdit && (
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    label="Edit"
                    className="mt-5 bg-transparent border-[#0353A4] text-[#0353A4]"
                    icon={<Icon name={Icons.EditPencil} />}
                    iconPosition="left"
                    iconLabelSpacing={5}
                    onClick={() => setShowEdit(true)}
                  />
                )}
              </div>
            )}
          </div>
          {/* Respresentative */}
          <div className="py-5 mt-2 border-b">
            <div className="flex items-center justify-between">
              <h2 className="text-xl text-[#263238] font-semibold">
                Representative Information
              </h2>
              <button
                type="button"
                className={`${accordian.rep ? 'rotate-180' : ''}`}
                onClick={() => handleAccordian('rep', accordian.rep)}
              >
                <Icon name={Icons.CaretDown} />
              </button>
            </div>
            {accordian.rep && (
              <div>
                {(docs[0]?.businessID?.repsDetails ?? []).map((resp) => (
                  <div
                    className="grid flex-1 grid-cols-1 pb-6 mt-10 gap-y-5 sm:grid-cols-2"
                    key={resp._id}
                  >
                    <div className="flex flex-col gap-2">
                      <p className="text-sm font-medium text-black/40">Reps First Name</p>
                      <span className="text-lg font-medium text-black font-inter">
                        {resp.firstName}
                      </span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-sm font-medium text-black/40">Reps Last Name</p>
                      <span className="text-lg font-medium text-black font-inter">
                        {resp.lastName}
                      </span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-sm font-medium text-black/40">Reps Email</p>
                      <span className="text-lg font-medium text-black font-inter">
                        {resp.email}
                      </span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-sm font-medium text-black/40">Reps Position</p>
                      <span className="text-lg font-medium text-black font-inter">
                        {resp.position}
                      </span>
                    </div>
                  </div>
                ))}
                {!showEdit && (
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    label="Edit"
                    className="mt-5 bg-transparent border-[#0353A4] text-[#0353A4]"
                    icon={<Icon name={Icons.EditPencil} />}
                    iconPosition="left"
                    iconLabelSpacing={5}
                    onClick={() => setShowEdit(true)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {showEdit && (
        <EditCompliance
          business={docs[0].businessID}
          onSave={(values) => handleSubmit(values as FormDto)}
        />
      )}
    </div>
  );
}
