import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import { MdOpenInNew } from 'react-icons/md';
import './BusinessPage.scss';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useGlobalContext } from '../../../../Context/Context';
import { axiosCalls } from '../../../../Api/_axios';
import { useNavigate, useParams } from 'react-router-dom';
import Loader1 from '../../../../Assests/loader.gif';
import axios from 'axios';
import PageLoader from '../../../../Components/Loader/Loader';
import Loader from '../../../../Assests/loader2.gif';
import Button from '../../../../Components/Elements/Button/Button';

function BusinessPage({ notify }: any) {
  const { toggleSidebar } = useGlobalContext();
  const token = sessionStorage.getItem('adminToken');
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [businessData, setBusinessData] = useState<any>({});
  const [userLoading, setUserLoading] = useState(true);
  const [userLiast, setUserLiast] = useState<any>([]);
  const navigate = useNavigate();
  const [Error, setError] = useState(false);
  const [docArray, setDocArray] = useState<any>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axiosCalls(
          `sysadmin/business/fetch/${id}`,
          'GET',
          null,
          token
        );
        if (result.er === 'Network Error') {
          setError(true);
        }
        setLoading(false);
        setBusinessData(result.data);
        setDocArray(result.data.kybDocument);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axiosCalls(
          `sysadmin/business/members/fetch?limit=100&id=${id}`,
          'GET',
          null,
          token
        );
        setUserLoading(false);
        setUserLiast(result.data.docs);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  function handleDownloadImage(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = url;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  }
  const [showPage, setShowPage] = useState('main');
  const [acceptModal, setAcceptModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const onAccept = (status: string) => {
    setRequestLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/sysadmin/business/verification/update`;
    const params = {
      status: status,
      id: id,
    };
    axios
      .put(url, null, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle successful response
        if (response.status === 200) {
          setRequestLoading(false);
          notify('success', response.data.message);
          window.location.reload();
        } else {
          notify('error', response.data.message);
          setRequestLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        // Handle error
      });
  };

  const handleDocClick = (id: string) => {
    // Find the clicked object
    const clickedObject = docArray.find((obj: any) => obj._id === id);

    // Shuffle the array and move the clicked object to the front
    const shuffledArray: any = docArray
      .filter((obj: any) => obj._id !== id)
      .sort(() => Math.random() - 0.5);
    shuffledArray.unshift(clickedObject);

    // Update the state with the shuffled array
    setDocArray(shuffledArray);
    setShowPage('media');
  };
  return loading ? (
    <PageLoader />
  ) : (
    <div className="businessPage">
      {Error ? (
        <h1>An Error occured, please check internet connection and refresh</h1>
      ) : (
        <>
          {acceptModal && (
            <div className="decline">
              <div className="modal">
                <div className="close">
                  <FaTimes onClick={() => setAcceptModal(false)} />
                </div>
                <h1>Are you sure you want to APPROVE/Activate BUSINESS?</h1>
                {requestLoading ? (
                  <div className="buttons">
                    <img alt="loader" src={Loader} />
                  </div>
                ) : (
                  <div className="buttons">
                    <Button
                      onClick={() => setAcceptModal(false)}
                      outlined
                      content="Cancel"
                    />
                    <Button onClick={() => onAccept('active')} content="Approve" />
                  </div>
                )}
              </div>
            </div>
          )}
          {declineModal && (
            <div className="decline">
              <div className="modal">
                <div className="close">
                  <FaTimes onClick={() => setDeclineModal(false)} />
                </div>
                <h1>Are you sure you want to DECLINE approval request?</h1>
                {requestLoading ? (
                  <div className="buttons">
                    <img alt="loader" src={Loader} />
                  </div>
                ) : (
                  <div className="buttons">
                    <Button
                      onClick={() => setDeclineModal(false)}
                      outlined
                      content="Cancel"
                    />
                    <Button
                      onClick={() => onAccept('deactivated')}
                      danger
                      content="Decline"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {deactivateModal && (
            <div className="decline">
              <div className="modal">
                <div className="close">
                  <FaTimes onClick={() => setDeactivateModal(false)} />
                </div>
                <h1>Are you sure you want to Deactivate Business?</h1>
                {requestLoading ? (
                  <div className="buttons">
                    <img alt="loader" src={Loader} />
                  </div>
                ) : (
                  <div className="buttons">
                    <Button
                      onClick={() => setDeactivateModal(false)}
                      outlined
                      content="Cancel"
                    />
                    <Button
                      onClick={() => onAccept('deactivated')}
                      danger
                      content="Deactivate"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {showPage === 'main' && (
            <>
              <div className="topG">
                <div className="left dif">
                  <div className="bars">
                    <FaBars onClick={toggleSidebar} />
                  </div>
                </div>
                <div className="right">
                  <h2 onClick={() => navigate('/admin/businesses')}>{'<'} Back</h2>
                </div>
              </div>
              <div className="single_profile_body">
                <div className="left">
                  <div className="head">
                    <div className="head_left">
                      <h2>Business Profile</h2>
                    </div>
                    <div className="head_right">
                      {businessData.status !== 'deactivated' && (
                        <p onClick={() => setDeactivateModal(true)}>
                          Click here to deactivate user’s account
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="user_id">
                    <div className="img">
                      <img
                        alt={`${businessData.profileImageURL}`}
                        src={
                          businessData.profileImageURL ===
                          'https://res.cloudinary.com/themizehq/image/upload/business_profile_image/64ce3c22b90502a7ddd4fd68/file.png'
                            ? 'https://winstonproducts.us/wp-content/uploads/2018/03/winston-staff-member-image-fallback-male.png'
                            : businessData.profileImageURL
                        }
                      />
                    </div>
                    <div className="dk">
                      <div className="card">
                        <h2>{businessData.status}</h2>
                        <p>Status</p>
                      </div>
                      <div className="card">
                        <h2>{businessData.rcNumber}</h2>
                        <p>Rc Number</p>
                      </div>
                    </div>
                  </div>
                  <div className="form_like">
                    <section>
                      <div className="card">
                        <h2>BUSINESS NAME</h2>
                        <div className="input">
                          <p>{businessData.name}</p>
                        </div>
                      </div>
                      <div className="card">
                        <h2>DATE CREATED</h2>
                        <div className="input">
                          <p>{new Date(businessData.createdAt).toDateString()}</p>
                        </div>
                      </div>
                      <div className="card">
                        <h2>Email</h2>
                        <div className="input">
                          <p>{businessData.email}</p>
                        </div>
                      </div>
                      <div className="card">
                        <h2>Phone NUMBER</h2>
                        <div className="input">
                          <p>{businessData.phone}</p>
                        </div>
                      </div>
                      <div className="card">
                        <h2>Industry Type</h2>
                        <div className="input">
                          <p>{businessData.industryType}</p>
                        </div>
                      </div>
                      <div className="card">
                        <h2>Company Size</h2>
                        <div className="input">
                          <p>{businessData.companySize}</p>
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="tags">
                        <h1>Address(es)</h1>
                      </div>
                      {businessData.addresses.map((item: any) => (
                        <div className="address" key={item._id}>
                          <div className="card">
                            <h2>Address</h2>
                            <div className="input">
                              <p>{item.address}</p>
                            </div>
                          </div>
                          <div className="card">
                            <h2>State</h2>
                            <div className="input">
                              <p>{item.state}</p>
                            </div>
                          </div>
                          <div className="card">
                            <h2>Postal</h2>
                            <div className="input">
                              <p>{item.postalCode}</p>
                            </div>
                          </div>
                          <div className="card">
                            <h2>Country</h2>
                            <div className="input">
                              <p>{item.country}</p>
                            </div>
                          </div>
                          <div className="card">
                            <h2>Address Type</h2>
                            <div className="input">
                              <p>{item.addressType}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </section>
                    <section>
                      <div className="tags">
                        <h1>Director(s)</h1>
                      </div>
                      {businessData.directorsDetails.map((item: any) => (
                        <div className="address" key={item._id}>
                          <div className="card">
                            <h2>Fullname</h2>
                            <div className="input">
                              <p>{item.fullName}</p>
                            </div>
                          </div>
                          <div className="card">
                            <h2>NIN</h2>
                            <div className="input">
                              <p>{item.NIN}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </section>
                    <section>
                      <div className="tags">
                        <h1>Bank Info(s)</h1>
                      </div>
                      {businessData.bankInfo.map((item: any) => (
                        <div className="address" key={item._id}>
                          <div className="card">
                            <h2>Bank Name</h2>
                            <div className="input">
                              <p>{item.bankName}</p>
                            </div>
                          </div>
                          <div className="card">
                            <h2>Account Name</h2>
                            <div className="input">
                              <p>{item.accountName}</p>
                            </div>
                          </div>
                          <div className="card">
                            <h2>Account Number</h2>
                            <div className="input">
                              <p>{item.accountNumber}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </section>
                    {/* <section>
                      <div className='tags'>
                        <h1>Stats</h1>
                      </div>
                      <div className='card'>
                        <h2>Rating</h2>
                        <div className='input'>
                          <p>{businessData.rating.toLocaleString()}</p>
                        </div>
                      </div>
                      <div className='card'>
                        <h2>Reviews Count</h2>
                        <div className='input'>
                          <p>{businessData.reviewsCount.toLocaleString()}</p>
                        </div>
                      </div>
                      <div className='card'>
                        <h2>Total Transactions</h2>
                        <div className='input'>
                          <p>
                            {businessData.totalTransactions.toLocaleString()}
                          </p>
                        </div>
                      </div>
                    </section> */}
                  </div>
                </div>
                <div className="right">
                  <div className="head">
                    <div className="head_top">
                      <h2>Document Preview</h2>
                      <MdOpenInNew onClick={() => setShowPage('media')} />
                    </div>
                    <div className="head_swipe">
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper"
                      >
                        {docArray.map((item: any) => (
                          <SwiperSlide>
                            <div
                              tabIndex={0}
                              role="button"
                              key={item._id}
                              onClick={() => handleDocClick(item._id)}
                            >
                              {item.documentURL.endsWith('.pdf') ? (
                                <iframe
                                  title={`${item.documentURL}-iframe`}
                                  src={item.documentURL}
                                ></iframe>
                              ) : (
                                <img src={item.documentURL} alt="" />
                              )}

                              <h3>{item.name}</h3>
                              <div className="buttons">
                                <button
                                  onClick={() => handleDownloadImage(item.documentURL)}
                                >
                                  Download
                                </button>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                  <div className="head">
                    <div className="head_top">
                      <h2 className="nh">Organization Users</h2>
                      <MdOpenInNew onClick={() => setShowPage('users')} />
                    </div>
                    {userLoading ? (
                      <div className="loadingU">
                        <img src={Loader1} alt="" />
                      </div>
                    ) : (
                      <div className="head_wrap">
                        {userLiast.map((item: any) => (
                          <div className={'card flex'} key={item.email}>
                            <h2>
                              {item.firstName} {item.lastName}
                            </h2>
                            <p>{item.role}</p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="topG">
                <div className="left"></div>
                {businessData.status === 'pending' ? (
                  <div className="right">
                    <button onClick={() => setDeclineModal(true)}>Deny</button>
                    <button onClick={() => setAcceptModal(true)}>Approve</button>
                  </div>
                ) : (
                  <div className="right">
                    {businessData.status === 'deactivated' ? (
                      <button onClick={() => setAcceptModal(true)}>Activate</button>
                    ) : (
                      <button onClick={() => setDeactivateModal(true)}>Deactivate</button>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
          {showPage === 'media' && (
            <>
              <div className="back">
                <h2 onClick={() => setShowPage('main')}>{'<'} Back</h2>
              </div>
              <div className="medias">
                <div className="swiperprol">
                  <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                    {docArray.map((item: any) => (
                      <SwiperSlide>
                        <div>
                          <h3>{item.name}</h3>
                          {/* <div className='icons'>
                        <AiOutlineRotateLeft />
                        <MdOutline360 />
                        <AiOutlineRotateRight />
                      </div> */}
                          {item.documentURL.endsWith('.pdf') ? (
                            <iframe
                              title={`${item.documentURL}-iframe`}
                              src={item.documentURL}
                            ></iframe>
                          ) : (
                            <img src={item.documentURL} alt="" />
                          )}

                          <div className="buttons">
                            <button onClick={() => handleDownloadImage(item.documentURL)}>
                              Download
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {/* <div className='buttons'>
        <button>Request for Update</button>
        <button>Print</button>
        <button>Download</button>
      </div> */}
                </div>
              </div>
            </>
          )}
          {showPage === 'users' && (
            <>
              <div className="back">
                <h2 onClick={() => setShowPage('main')}>{'<'} Back</h2>
              </div>
              <div className="tables">
                <table>
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userLiast.map((item: any, i: number) => (
                      <tr key={item.email}>
                        <td>{i + 1}</td>
                        <td>
                          {item.firstName} {item.lastName}
                        </td>
                        <td>{item.email}</td>
                        <td>{item.role}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default BusinessPage;
