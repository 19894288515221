import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface Props {
  notify: any;
}
const AdminRoute = ({ notify }: Props) => {
  const token = sessionStorage.getItem('adminToken');

  if (token) {
    return <Outlet />;
  } else {
    return (
      <>
        {notify('warn', 'Access denied! Please login')}
        <Navigate to="/admin/login" />
      </>
    );
  }
};
export default AdminRoute;
