import React, { useEffect, useState } from 'react';
import { Icon, Icons } from '../../../../Components/Icon';
import AddNewRoleModal from '../../../../Components/Modal/AddNewRoleModal';
import AddNewEmployee from '../../../../Components/Modal/AddNewEmployee';
import CheckPermissions from '../../../../Components/Unauthorized/CheckPermissions';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { useGetRole } from '../../../../hooks/queries-and-mutations/settings';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import { RoleDoc } from '../../../../types/setting.type';
import { useGetEmployee } from '../../../../hooks/queries-and-mutations/business';
import { EmployeeDoc } from '../../../../types/business.type';
import { archiveEmployeeDetails, archiveRole } from '../../../../Api/apiCalls';
// import { archiveRole } from "../../../../Api/apiCalls";

const EhancedAddNewRoleModal = withCreatePortal(AddNewRoleModal);
const EhancedAddNewEmployeeModal = withCreatePortal(AddNewEmployee);

export default function Collaborators() {
  const [showAddRole, setShowAddRole] = useState(false);
  const [showAddEmployer, setShowAddEmployer] = useState(false);
  const [selectedRole, setSelectedRole] = useState<RoleDoc | null>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeDoc | null>(null);
  const [accordian, setAccordian] = useState({
    role: false,
    employee: false,
  });

  const { docs } = useAppSelector(selectBusiness);
  const { data: roles, refetch } = useGetRole({
    businessId: docs[0].businessID._id,
    page: 1,
  });

  const { data: employees, refetch: getEmployee } = useGetEmployee({
    businessId: docs[0].businessID._id,
    page: 1,
  });

  const handleAccordian = (field: string, value: boolean) => {
    setAccordian((prevState) => ({ ...prevState, [field]: !value }));
  };

  const [employeeRoles, setEmployeeRoles] = useState(roles?.docs ?? []);

  useEffect(() => {
    setEmployeeRoles(roles?.docs ?? []);
  }, [roles]);

  const [employeeDetails, setEmployeeDetails] = useState(
    employees?.docs ?? [] ? employees?.docs : undefined
  );

  const archiveARole = async (id: string) => {
    const res = await archiveRole(docs[0].businessID._id, id);

    if (res && res.status === 201 && res.data) {
      setEmployeeRoles((prev) => prev?.filter((res) => res._id !== id));
    }
  };

  const archiveEmployee = async (userId: string) => {
    const response = await archiveEmployeeDetails(docs[0].businessID._id, userId);
    if (response?.data) {
      setEmployeeDetails((prev) => prev?.filter((res) => res?.userID !== userId));
    }
  };

  return (
    <div className="w-full">
      <div className="border-b pl-9 py-5">
        <p className="font-semibold text-2xl font-inter text-black">Collaborators</p>
      </div>
      <div className="p-8">
        {/* Roles */}
        <CheckPermissions
          requiredPermissions={['create_business_role', 'update_business_role']}
        >
          <div className="pb-5 border-b">
            <div className="flex items-center justify-between">
              <span className="text-xl text-black font-inter font-semibold">Roles</span>
              <button
                type="button"
                className={`${accordian.role ? 'rotate-180' : ''}`}
                onClick={() => handleAccordian('role', accordian.role)}
              >
                <Icon name={Icons.CaretDown} />
              </button>
            </div>
            {accordian.role && (
              <div className="mt-9">
                <div className="rounded table-responsive">
                  <table>
                    <thead className="bg-[#0353A4B0] text-white rounded mb-3">
                      <tr>
                        <th>Roles</th>
                        <th>Permissions</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="bg-[#B9D6F224]">
                      {(employeeRoles ?? [])?.map((item) => (
                        <>
                          <tr key={item._id}>
                            <td className="whitespace-nowraps">{item.name}</td>
                            <td className="whitespace-nowraps">
                              {item.permissions
                                .map((permission: string) =>
                                  permission.replace(/_/g, ' ')
                                )
                                .join(', ')}{' '}
                            </td>
                            <td className="flex items-center p-3 text-center divide-x space-x-3">
                              {!item.permissions.includes('*') && (
                                <>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setSelectedRole(item);
                                      setShowAddRole(true);
                                    }}
                                  >
                                    <Icon name={Icons.EditPencil} />
                                  </button>
                                  <button
                                    onClick={() => archiveARole(item?._id)}
                                    className="pl-3"
                                    type="button"
                                  >
                                    <Icon name={Icons.Delete} />
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                          <tr className="h-1"></tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                  {!(roles?.docs ?? []).length && (
                    <tbody className="rounded w-full p-10 bg-[#B9D6F224] flex flex-col items-center justify-center">
                      <p className="text-lg font-medium font-inter">No role added yet</p>
                      <div
                        role="button"
                        tabIndex={0}
                        className="flex items-center gap-1 mt-3 cursor-pointer"
                        onClick={() => setShowAddRole(true)}
                      >
                        <Icon name={Icons.Add} fill="#0353A4" width={16} height={16} />
                        <span className="text-[#0353A4] text-sm font-medium font-inter">
                          Add new role
                        </span>
                      </div>
                    </tbody>
                  )}
                </div>
                <div className="flex items-center mt-8 pb-9">
                  <div
                    tabIndex={0}
                    className="flex items-center gap-1"
                    role="button"
                    onClick={() => setShowAddRole(true)}
                  >
                    <Icon name={Icons.Add} />
                    <span className="text-sm font-medium text-[#000000]">
                      Add another role
                    </span>
                  </div>
                  <div className="line ml-5" style={{ width: '80%' }} />
                </div>
              </div>
            )}
          </div>
        </CheckPermissions>

        {/* Employees */}
        <CheckPermissions
          requiredPermissions={['view_business_member', 'update_business_member']}
        >
          <div className="pb-5 border-b mt-9">
            <div className="flex items-center justify-between">
              <span className="text-xl font-semibold text-black font-inter">
                Employees
              </span>
              <button
                type="button"
                className={`${accordian.employee ? 'rotate-180' : ''}`}
                onClick={() => handleAccordian('employee', accordian.employee)}
              >
                <Icon name={Icons.CaretDown} />
              </button>
            </div>
            {accordian.employee && (
              <div className="pb-5 mt-9">
                <div className="rounded table-responsive">
                  <table>
                    <thead className="bg-[#0353A4B0] text-white rounded mb-3">
                      <tr>
                        <th>Name</th>
                        <th>Roles</th>
                        <th>Department</th>
                        <th>Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="bg-[#B9D6F224]">
                      {(employeeDetails ?? [])?.map((item) => (
                        <>
                          <tr className={`${item.blocked ? 'opacity-30' : ''}`}>
                            <td className="whitespace-nowraps">{`${item.firstName} ${item.lastName}`}</td>
                            <td className="whitespace-nowraps">{item.role}</td>
                            <td className="whitespace-nowraps">
                              {item.department?.name ?? 'All'}
                            </td>
                            <td className="whitespace-nowraps">
                              <span
                                className={`mr-2 inline-flex items-center justify-center w-3 h-3 ms-2 ${
                                  item.blocked ? '#F48D14E8' : 'bg-[#216509E8]'
                                } rounded-full`}
                              ></span>
                              {item.blocked ? 'Inactive' : 'Active'}
                            </td>
                            <td className="flex items-center p-3 space-x-3 text-center divide-x">
                              <button
                                type="button"
                                onClick={() => {
                                  setShowAddEmployer(true);
                                  setSelectedEmployee(item);
                                }}
                              >
                                <Icon name={Icons.EditPencil} />
                              </button>
                              <button
                                className="pl-3"
                                type="button"
                                onClick={() => {
                                  archiveEmployee(item.userID);
                                }}
                              >
                                <Icon name={Icons.Delete} />
                              </button>
                            </td>
                          </tr>
                          <tr className="h-1"></tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                  {!(employeeDetails ?? []).length && (
                    <tbody className="rounded w-full p-10 bg-[#B9D6F224] flex flex-col items-center justify-center">
                      <p className="text-lg font-medium font-inter">
                        No employee added yet
                      </p>
                      <div
                        role="button"
                        tabIndex={0}
                        className="flex items-center gap-1 mt-3 cursor-pointer"
                        onClick={() => setShowAddEmployer(true)}
                      >
                        <Icon name={Icons.Add} fill="#0353A4" width={16} height={16} />
                        <span className="text-[#0353A4] text-sm font-medium font-inter">
                          Add new employee
                        </span>
                      </div>
                    </tbody>
                  )}
                </div>
                <div className="flex items-center mt-8 pb-9">
                  <div
                    tabIndex={0}
                    className="flex items-center gap-1"
                    role="button"
                    onClick={() => setShowAddEmployer(true)}
                  >
                    <Icon name={Icons.Add} />
                    <span className="text-sm font-medium text-[#000000]">
                      Add another employee
                    </span>
                  </div>
                  <div className="line ml-5" style={{ width: '79%' }} />
                </div>
              </div>
            )}
          </div>
        </CheckPermissions>
      </div>
      {showAddRole && (
        <EhancedAddNewRoleModal
          onCancel={() => {
            setShowAddRole(false);
            setSelectedRole(null);
          }}
          onSuccess={() => {
            setShowAddRole(false);
            setSelectedRole(null);
            refetch();
          }}
          businessId={docs[0]?.businessID?._id ?? ''}
          selectedRole={selectedRole}
        />
      )}
      {showAddEmployer && (
        <EhancedAddNewEmployeeModal
          onCancel={() => {
            setSelectedEmployee(null);
            setShowAddEmployer(false);
          }}
          onSuccess={() => {
            setShowAddEmployer(false);
            setSelectedEmployee(null);
            getEmployee();
          }}
          selectedEmployee={selectedEmployee}
        />
      )}
    </div>
  );
}
