import './MainLayout.scss';
import { useGlobalContext } from '../../Context/Context';

export const MainLayout = ({ children }: any) => {
  const { divRef, sideBar, setSideBar } = useGlobalContext();
  return (
    <div
      role="main"
      className="mainLayout"
      ref={divRef}
      onClick={() => {
        if (!sideBar) {
          setSideBar(!sideBar);
        }
      }}
    >
      <div className="cover">
        <div className="bodyx">{children}</div>
      </div>
    </div>
  );
};
