import React, { useState } from 'react';
import { Icon, Icons } from '../../../../../Components/Icon';
import Button from '../../../../../Components/Form/Button';
import PaymentModal from '../../../../../Components/Modal/PaymentModal';
import withCreatePortal from '../../../../../Components/Hoc/withCreatePortal';
import { useGetPlans } from '../../../../../hooks/queries-and-mutations/subscription';
import { Plan as PlanType } from '../../../../../types/subscription.type';
import { formatCurrency } from '../../../../../utils';
import { useAppSelector } from '../../../../../store/hooks';
import { selectBusiness } from '../../../../../selectors/business-selector';

const EnhancedPaymentModal = withCreatePortal(PaymentModal);

export default function Plan() {
  const { docs } = useAppSelector(selectBusiness);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PlanType | null>(null);

  const { data } = useGetPlans();

  return (
    <div>
      <div className="mb-5">
        <div className="max-w-[320px] md:max-w-[990px]">
          <div className="justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
            {(data ?? []).map((plan) => (
              <div
                key={plan?._id}
                className="flex flex-col justify-between group rounded-[10px] border border-black/20 p-3s hover:border-[#0353A4] p-8 py-9"
              >
                <div>
                  <h3 className="text-xl lg:text-2xl">{plan?.name}</h3>
                  <div className="mx-auto my-6 w-1/5 group-hover:border-primary" />
                  <div className="my-5 text-lg group-hover:text-[#0353A4]">
                    <strong className="text-xl font-semibold text-black group-hover:text-[#0353A4] lg:text-xl">
                      {formatCurrency(plan?.currency, plan?.price_per_seat)}
                    </strong>
                    <span className="font-medium text-xs text-black/60">
                      {' '}
                      / seat / month
                    </span>
                  </div>
                  <p className="text-xs font-normal text-black">Enjoy these features</p>

                  <ul className="my-5 space-y-5 font-normal text-xs group-hover:text-[#0353A4] text-black/40">
                    {(plan?.features ?? []).map((feature) => (
                      <li className="flex items-center justify-center" key={feature}>
                        <Icon name={Icons.Mark} />
                        <span className="ml-2">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <Button
                  type="button"
                  variant="custom"
                  size="custom"
                  label="Subscribe"
                  className="py-2.5 px-20 mt-20 bg-transparent border border-[#0353A4] text-[#0353A4]"
                  onClick={() => {
                    setShowPaymentModal(true);
                    setSelectedPlan(plan);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {showPaymentModal && (
        <EnhancedPaymentModal
          onCancel={() => setShowPaymentModal(false)}
          onSuccess={() => setShowPaymentModal(false)}
          plan={selectedPlan}
          email={docs[0].businessID.email}
          businessId={docs[0].businessID._id}
        />
      )}
    </div>
  );
}
