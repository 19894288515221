import { useEffect, useState } from 'react';
import { Icon, Icons } from '../../../../../Components/Icon';
import Button from '../../../../../Components/Form/Button';
import { ApprovalTypeDoc, PayableApproval } from '../../../../../types/approval.type';
import { getLevelName } from '../../../../../utils';
import withCreatePortal from '../../../../../Components/Hoc/withCreatePortal';
import AddPersonModal from '../../../../../Components/Modal/AddPersonModal';
import ConfirmModal from '../../../../../Components/Modal/ConfirmModal';
import {
  useAddPersonApproval,
  useBlockPersonApproval,
  useGetPerson,
} from '../../../../../hooks/queries-and-mutations/settings';
import { toast } from 'react-toastify';

type IProps = {
  businessId: string;
  payable: PayableApproval;
  refetch: () => void;
};

const EhancedAddPersonModal = withCreatePortal(AddPersonModal);
const EhancedConfirmModal = withCreatePortal(ConfirmModal);

export default function Payable({ businessId, payable, refetch }: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const [showAddPerson, setShowAddPerson] = useState(false);
  const [fieldValue, setFieldValue] = useState<any>();
  const [level, setLevel] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmBlockModal, setShowConfirmBlockModal] = useState(false);
  const [isBlock, setIsBlock] = useState<'true' | 'false' | ''>('');
  const [selectedUser, setSelectedUser] = useState<ApprovalTypeDoc>();

  const { mutate, isSuccess, isError } = useAddPersonApproval();
  const {
    mutate: blockApproval,
    isSuccess: isBlockSuccess,
    isError: isBlockError,
  } = useBlockPersonApproval();

  const { data } = useGetPerson({ businessId, params: '?not_on=payable' });

  const handleSubmit = () => {
    if (fieldValue.length) {
      setIsSubmitting(true);
      const payload = {
        approvals: {
          payable: {
            [level]: fieldValue,
          },
        },
        businessId,
      };
      mutate(payload);
    } else {
      toast.success('Select atleast one person');
    }
  };

  const handleBlock = () => {
    if (selectedUser) {
      setIsSubmitting(true);
      const payload = {
        block: {
          receivable: {
            [`${isBlock}`]: [selectedUser?._id],
          },
        },
        businessId,
      };
      blockApproval(payload);
    } else {
      toast.success('Select atleast one person');
    }
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      refetch();
      toast.success('Approval granted successfully ');
      setIsSubmitting(false);
      setShowAddPerson(false);
      setShowConfirmModal(false);
    } else if (isError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess]);

  useEffect(() => {
    if (isBlockSuccess && !isBlockError) {
      refetch();
      toast.success(`User successfully ${isBlock === 'true' ? 'blocked' : 'unblocked'}`);
      setIsSubmitting(false);
      setShowAddPerson(false);
      setShowConfirmModal(false);
    } else if (isBlockError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlockError, isBlockSuccess]);

  return (
    <div>
      <div className="pb-4 border-b">
        <div
          role="button"
          tabIndex={0}
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setShowActive((prevState) => !prevState)}
        >
          <p className="text-xl font-semibold font-inter">Payable</p>
          <Icon name={showActive ? Icons.CaretUp : Icons.CaretDown} />
        </div>

        {showActive && (
          <div className="mt-6">
            {Object.keys(payable).map((level) => {
              return (
                <div className="mt-10" key={level}>
                  <p className="my-2 font-medium text-black text-md">
                    {getLevelName(Number(level))}
                  </p>
                  <div className="rounded table-responsive">
                    <table>
                      <thead className="bg-[#0353A4B0] text-white rounded mb-3">
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody className="bg-[#B9D6F224]">
                        {payable[level].map((item) => {
                          return (
                            <tr key={item._id}>
                              <td className="whitespace-nowraps">{`${item.firstName} ${item.lastName}`}</td>
                              <td className="whitespace-nowraps">{item.email}</td>
                              <td className="p-3 space-x-3 text-center divide-x">
                                <>
                                  {item.approvals.receivable.blocked ? (
                                    <button
                                      className="pl-3"
                                      type="button"
                                      onClick={() => {
                                        setShowConfirmBlockModal(true);
                                        setIsBlock('false');
                                        setSelectedUser(item);
                                      }}
                                    >
                                      <Icon name={Icons.EyeOpen} />
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setShowConfirmBlockModal(true);
                                        setIsBlock('true');
                                        setSelectedUser(item);
                                      }}
                                    >
                                      <Icon name={Icons.EyeClose} />
                                    </button>
                                  )}
                                </>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="flex flex-col items-end">
                    <Button
                      type="button"
                      variant="primary"
                      size="custom"
                      label="Add person"
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      className="px-5 py-3 mt-5"
                      onClick={() => {
                        setLevel(Number(level));
                        setShowAddPerson(true);
                      }}
                    />
                  </div>
                </div>
              );
            })}
            {!Object.keys(payable).length && (
              <div className="rounded table-responsive">
                <table>
                  <thead className="bg-[#0353A4B0] text-white rounded mb-3">
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                </table>
                <tbody className="rounded w-full p-10 bg-[#B9D6F224] flex flex-col items-center justify-center">
                  <p className="text-lg font-medium font-inter">No person added yet</p>
                  <div
                    role="button"
                    tabIndex={0}
                    className="flex items-center gap-1 mt-3 cursor-pointer"
                    onClick={() => {
                      setLevel(1);
                      setShowAddPerson(true);
                    }}
                  >
                    <Icon name={Icons.Add} fill="#0353A4" width={16} height={16} />
                    <span className="text-[#0353A4] text-sm font-medium font-inter">
                      Add new user
                    </span>
                  </div>
                </tbody>
              </div>
            )}
            {Object.keys(payable).length > 1 && (
              <div className="mt-5">
                <div className="flex items-center mt-5 pb-9">
                  <div
                    className="flex items-center gap-1"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setLevel(Object.keys(payable).length + 1);
                      setShowAddPerson(true);
                    }}
                  >
                    <Icon name={Icons.Add} />
                    <span className="text-sm font-medium text-[#000000]">
                      Add another level
                    </span>
                  </div>
                  <div className="ml-5 line" style={{ width: '85%' }} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {showAddPerson && (
        <EhancedAddPersonModal
          onCancel={() => {
            setShowAddPerson(false);
          }}
          onSubmit={(payload: string[]) => {
            setFieldValue(payload);
            setShowAddPerson(false);
            setShowConfirmModal(true);
          }}
          businessId={businessId ?? ''}
          persons={data}
        />
      )}

      {showConfirmModal && (
        <EhancedConfirmModal
          content={'Are you sure you want to grant these people approval right?'}
          onConfirm={() => {
            handleSubmit();
          }}
          onCancel={() => setShowConfirmModal(false)}
          cancelText="Cancel"
          actionText="Yes, Save"
          cancelClassName="w-[7rem] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-[7rem] px-5 py-2.5 text-sm bg-[#061A40] rounded font-normal text-white"
          widthClass={30}
        />
      )}

      {showConfirmBlockModal && (
        <EhancedConfirmModal
          content={`Are you sure you want to ${
            isBlock === 'true' ? 'block' : 'unblock'
          } ${selectedUser?.firstName} ${
            selectedUser?.lastName
          } from this level approval ?`}
          onConfirm={() => {
            handleBlock();
          }}
          onCancel={() => setShowConfirmBlockModal(false)}
          cancelText="Cancel"
          actionText="Remove"
          cancelClassName="w-[7rem] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-[7rem] px-5 py-2.5 text-sm bg-[#AA1414] rounded font-normal text-white"
          widthClass={30}
        />
      )}
    </div>
  );
}
