import { AxiosError } from 'axios';
import { axiosCalls } from './_axios';
import { errorHandler, getToken } from '../service';
import axios from 'axios';
import { toast } from 'react-toastify';
export const getCostOfSeats = async (businessId: string, seat: string) => {
  try {
    const response = await axiosCalls(
      `/subscription/${businessId}/cost?seat=${seat}`,
      'GET',
      null,
      getToken()
    );

    return response.data.cost;
  } catch (error) {
    const err = error as AxiosError<Error>;
    toast.error(
      err.message ?? 'Sorry!, an error as occurred while trying to get costs of seats'
    );
    errorHandler(err);
  }
};

export const archiveRole = async (businessId: string, id: string) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/role/${businessId}/archive`,
      method: 'patch',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: {
        id: id,
      },
    });
    return response;
  } catch (error) {
    const err = error as AxiosError<Error>;
    toast(err.message ?? 'An error has occurred');
  }
};
export const archiveEmployeeDetails = async (businessId: string, id: string) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/business/${businessId}/members/${id}/block`,
      method: 'patch',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: {
        id: id,
      },
    });
    if (response.data && response.status === 201) {
      toast.success('Enployee archived successfully');
      return response;
    }
  } catch (err) {
    const error = err as AxiosError<Error>;
    toast(error.message ?? 'An error has occurred');
    errorHandler(error);
  }
};

export const archiveADept = async (businessId: string, id: string) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/department/${businessId}/archive`,
      method: 'patch',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: {
        id: id,
      },
    });
    if (response.data && response.status === 201) {
      return response;
    }
  } catch (err) {
    const error = err as AxiosError<Error>;
    toast(error.message ?? 'An error has occurred');
    errorHandler(error);
  }
};
