import React from 'react';

interface IProps {
  onViewDetail: () => void;
  onRateTransaction: () => void;
  onRatingDetails: () => void;
}

const TransactionTableAction: React.FC<IProps> = ({
  onRateTransaction,
  onViewDetail,
  onRatingDetails,
}) => {
  return (
    <div className="absolute right-0 w-44 bg-white shadow-xl z-50 rounded">
      <div className="flex flex-col justify-between text-left space-y-6s">
        <p
          className="px-5 py-4 text-sm cursor-pointer hover:bg-[#0353A4] hover:text-white"
          onClick={onViewDetail}
        >
          View detail
        </p>
        <p
          className="px-5 py-4 text-sm cursor-pointer hover:bg-[#0353A4] hover:text-white"
          onClick={onRatingDetails}
        >
          View Rate
        </p>
        <p
          className="px-5 py-4 text-sm cursor-pointer hover:bg-[#0353A4] hover:text-white"
          onClick={onRateTransaction}
        >
          Rate transaction
        </p>
      </div>
    </div>
  );
};

export default TransactionTableAction;
