import { useState } from 'react';
import { InfoModal } from '../InfoModal';
import Button from '../../Form/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
};

export default function SendReminderModal({ onCancel }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      note: '',
    },
    validationSchema: Yup.object({
      note: Yup.string().required('Note is required').nullable(),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      console.log(
        '🚀 ~ file: AddAccountModal.tsx:39 ~ AddAccountModal ~ values:',
        values
      );
      try {
        // dispatch(login({ email: email.trim(), password: password.trim() }));
      } catch (error) {
        console.log('Error', error);
      }
    },
  });

  return (
    <InfoModal width="w-[40%]" className="h-fit " onClose={onCancel}>
      <div className="font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col justify-between">
          <div className="py-5 border-b">
            <p className="text-xl font-medium text-black font-inter">Send Reminder</p>
            <p className="text-base font-normal font-inter text-black/40">
              You are about to send a reminder to Favourite promise
            </p>
          </div>

          <form className="w-full pt-6 mt-5" onSubmit={formik.handleSubmit}>
            <label
              htmlFor="note"
              className="font-medium font-inter text-sm text-[#000000]"
            >
              Add Note
            </label>
            <textarea
              id="note"
              name="note"
              rows={5}
              onChange={formik.handleChange}
              onBlur={formik.handleChange}
              placeholder="Tell us why you want to decline this invoice"
              className={`form-textarea text-sm font-inter font-normal rounded-lg  
                      ${
                        formik.touched.note && formik.errors.note
                          ? 'bg-red-50 border border-red-500 text-red-900 placeholder:text-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500'
                          : 'bg-gray-50 border border-[#0000001A] placeholder:text-white-dark '
                      }`}
              defaultValue={formik.values.note}
            />
            {formik.errors.note && (
              <p className="mt-2 text-xs text-red-600 capitalize">{formik.errors.note}</p>
            )}
            <div className="flex items-center w-full gap-5 mt-5">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                label="Send Reminder"
                className="my-5 px-5 py-2.5 text-sm text-white"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || isSubmitting}
              />
            </div>
          </form>
        </div>
      </div>
    </InfoModal>
  );
}
