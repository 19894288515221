type IProps = {
  applyFilter: (val: string) => void;
  options?: { value: string; label: string }[];
};

export default function FilterMoreOption({ applyFilter, options }: IProps) {
  return (
    <div className="absolute right-0 top-[40px] w-32 bg-white shadow-xl">
      <div className="flex flex-col justify-between">
        <div className="flex flex-col py-3">
          <p
            className="p-2 pt-2 text-sm cursor-pointer hover:bg-[#0353A40F]"
            onClick={() => applyFilter('')}
          >
            Reset
          </p>
          <div className="mt-2 border-b border-[#D9D9D9]" />
        </div>
        {(options || []).map(({ value, label }) => {
          return (
            <p
              key={value}
              className="p-4 text-sm cursor-pointer hover:bg-[#0353A40F]"
              onClick={() => applyFilter(value)}
            >
              {label}
            </p>
          );
        })}
      </div>
    </div>
  );
}
