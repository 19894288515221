export interface InvoiceType {
  docs: InvoiceDoc[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: any;
  nextPage: any;
}

export interface SingleInvoice {
  invoice: InvoiceDoc;
}

export interface InvoiceDoc {
  tax: Tax;
  discount: Discount;
  _id: string;
  paid: boolean;
  address: string;
  number: string;
  amount: number;
  status: string;
  country: string;
  state: string;
  date: string;
  products: Product[];
  note: string;
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  outstanding: number;
  createdBy: string;
  businessID: BusinessId;
  requiredEnd: string;
  sellerID: SellerId;
  sellerName: string;
  email: string;
  phone: string;
  fileURLs: any[];
  currency: string;
  paymentProof: any[];
  sellerApprovalNextLevel: string;
  buyerApprovalNextLevel: string;
  declinedAt: any;
  declineNote: any;
  approvers: any[];
  visibleToBuyer: boolean;
  buyerApprovedBy: any[];
  sellerApprovedBy: any[];
  createdAt: string;
  updatedAt: string;
}

export interface Tax {
  type: string;
  value: number;
}

export interface Discount {
  type: string;
  value: number;
}

export interface Product {
  product: Product2;
  quantity: number;
  _id: string;
}

export interface Product2 {
  _id: string;
  name: string;
  description: string;
  price: number;
  currency: string;
}

export interface SellerId {
  _id: string;
  lastInvoiceNumber: number;
  minIncomingInvoiceApprovalCount: number;
  minOutgoingInvoiceApprovalCount: number;
  minCreditApprovalCount: number;
  subAccountCode: string;
  email: string;
  name: string;
  directorsDetails: DirectorsDetail[];
  profileImageURL: string;
  blocked: boolean;
  createdBy: string;
  onBoardedBy: any;
  rating: number;
  reviewsCount: number;
  totalTransactions: number;
  type: string;
  phone: string;
  addresses: Address[];
  industryType: string;
  companySize: string;
  status: string;
  rcNumber: string;
  kybDocument: KybDocument[];
  bankInfo: BankInfo[];
  socialMediaInfo: SocialMediaInfo[];
  activatedDate?: string;
  repsDetails: RepsDetail[];
  creditAssessment: any[];
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface BusinessId {
  _id: string;
  lastInvoiceNumber: number;
  subAccountCode: string;
  email: string;
  name: string;
  directorsDetails: DirectorsDetail[];
  profileImageURL: string;
  blocked: boolean;
  createdBy: string;
  onBoardedBy: any;
  rating: number;
  reviewsCount: number;
  totalTransactions: number;
  type: string;
  phone: string;
  addresses: Address[];
  industryType: string;
  companySize: string;
  status: string;
  rcNumber: string;
  kybDocument: KybDocument[];
  bankInfo: BankInfo[];
  socialMediaInfo: any[];
  activatedDate: string;
  repsDetails: RepsDetail[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  minIncomingInvoiceApprovalCount: number;
  minOutgoingInvoiceApprovalCount: number;
  creditAssessment: any[];
  minCreditApprovalCount: number;
}

export interface DirectorsDetail {
  fullName: string;
  NIN: string;
  _id: string;
}

export interface Address {
  address: string;
  country: string;
  state: string;
  postalCode: string;
  addressType: string;
  _id: string;
}

export interface KybDocument {
  name: string;
  documentURL: string;
  _id: string;
}

export interface BankInfo {
  bankName: string;
  bankCode: string;
  accountName: string;
  accountNumber: string;
  _id: string;
}

export interface SocialMediaInfo {
  name: string;
  url: string;
  _id: string;
}

export interface RepsDetail {
  lastName: string;
  firstName: string;
  email: string;
  position: string;
  _id: string;
}

export interface BuyerApprovedBy {
  by: By;
  at: string;
  _id: string;
}

export interface SellerApprovedBy {
  by: By;
  at: string;
  _id: string;
}

export interface By {
  department?: string;
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  profileImageURL: string;
  blocked: boolean;
  createdBy?: string;
  role: string;
  businessName: string;
  userID: string;
  businessID: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface InvoiceDto {
  invoiceNumber: string;
  date: string;
  dueDate: string;
  currency: string;
  discountType?: string;
  discount?: number;
  taxType?: string;
  tax?: number;
  vendorName: string;
  vendorAddress: string;
  vendorCountry: string;
  vendorCity: string;
  products: Product[];
  note: string;
}

export interface Product {
  product: Product2;
  productDescription: string;
  price: number;
  quantity: number;
  amount: number;
}

export interface Product2 {
  value: string;
  label: string;
}

export enum InvoiceStatus {
  DRAFT = 'draft', // can be edited
  AWAITING_APPROVAL_SELLER = 'awaiting_internal_approval',
  DECLINED_BY_SELLER = 'declined_internally', // can be edited
  AWAITING_APPROVAL_BUYER = 'awaiting_customer_approval',
  DECLINED_BY_BUYER = 'customer_declined',
  APPROVED_FOR_FUNDING = 'approved_for_financing',
  OVERDUE = 'invoice_overdue',
  PAID_WITH_CREDIT = 'financed',
  OUTSTANDING = 'outstanding',
  PAID = 'paid',
  APPROVED = 'approved',

  paid = 'paid',
  draft = 'draft',
  outstanding = 'outstanding',
  financed = 'financed',
  overdue = 'overdue',
}
