import './DashboardLayout.scss';

const DashboardLayout = ({ children }: any) => {
  return (
    <div className="dashboardLayout">
      {/* <IdleTimer /> */}
      <>{children}</>
    </div>
  );
};

export default DashboardLayout;
