import { RxDashboard } from 'react-icons/rx';
import { TbWallet } from 'react-icons/tb';
import { FaEdit, FaList } from 'react-icons/fa';
import { MdBusiness } from 'react-icons/md';
import { BiLogOut } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

function SideBar({ page, notify }: any) {
  const navigate = useNavigate();
  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        className={page === 'home' ? 'card active' : 'card'}
        onClick={() => navigate('/admin/home')}
      >
        <div className="left">
          <RxDashboard />
        </div>
        <div className="right">
          <p>Home</p>
        </div>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={page === 'businesses' || page === 'business' ? 'card active' : 'card'}
        onClick={() => navigate('/admin/businesses ')}
      >
        <div className="left">
          <MdBusiness />
        </div>
        <div className="right">
          <p>Businesses</p>
        </div>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={
          page === 'business-update' || page === 'update-page' ? 'card active' : 'card'
        }
        onClick={() => navigate('/admin/business-update ')}
      >
        <div className="left">
          <FaEdit />
        </div>
        <div className="right">
          <p>Business Update</p>
        </div>
      </div>

      <div
        role="button"
        tabIndex={0}
        className={page === 'buyers' ? 'card active' : 'card'}
        onClick={() => navigate('/admin/transactions')}
      >
        <div className="left">
          <FaList />
        </div>
        <div className="right">
          <p>Transactions</p>
        </div>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={page === 'funders' ? 'card active' : 'card'}
        onClick={() => navigate('/admin/finance-request')}
      >
        <div className="left">
          <TbWallet />
        </div>
        <div className="right">
          <p>Finance Request</p>
        </div>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={page === 'buyers' ? 'card active' : 'card'}
        onClick={() => {
          notify('info', 'Logged Out');
          setTimeout(() => {
            sessionStorage.clear();
            navigate('/admin/login');
          }, 1000);
        }}
      >
        <div className="left">
          <BiLogOut />
        </div>
        <div className="right">
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
