import { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../Assests/5.png';
import DarkLogo from '../../../Assests/2.png';
import { useNavigate } from 'react-router-dom';

function Navbar({ label, setShowEarlyAccess }: any) {
  const [isActive, setActive] = useState<any>('false');
  const handleToggle = () => {
    setActive(!isActive);
  };
  const navigate = useNavigate();
  return (
    <nav
      className={label === 'auth' ? 'landing-navigation2 dark' : 'landing-navigation2'}
    >
      <div role="button" tabIndex={0} className="logo" onClick={() => navigate('/')}>
        {label === 'auth' ? (
          <img className="logo1" src={DarkLogo} alt="Logo" />
        ) : (
          <img className="logo1" src={Logo} alt="Logo" />
        )}
      </div>
      <div
        role="button"
        tabIndex={0}
        onClick={handleToggle}
        className={isActive ? 'hamburger' : 'hamburger toggle'}
      >
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
      <ul className={isActive ? 'nav-links mods' : 'nav-links mods open'}>
        <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
          <a href={label === 'hero' ? '#about' : '/'} className="link">
            About
          </a>
        </li>
        <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
          <a href={label === 'hero' ? '#features' : '/'} className="link">
            Features
          </a>
        </li>
        <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
          <a href={label === 'hero' ? '#benefits' : '/'} className="link">
            Benefits
          </a>
        </li>
        <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
          <a href={label === 'hero' ? '#pricing' : '/'} className="link">
            Pricing
          </a>
        </li>
        <li onClick={handleToggle} className={isActive ? '' : 'fade'}>
          <a href={label === 'hero' ? '#faqs' : '/'} className="link">
            FAQs
          </a>
        </li>
        {label === 'hero' && (
          <button className="left-btn maxi" onClick={() => setShowEarlyAccess(true)}>
            Get early access
          </button>
        )}
        <Link to="/auth/login">
          <button className="right-btn">Sign In</button>
        </Link>
        {/* <Link to='/create-account'>
          <button className='right-btn'>Sign Up</button>
        </Link> */}
      </ul>
    </nav>
  );
}

export default Navbar;
