import axios, { AxiosError } from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

export const baseService = axios.create({
  baseURL: baseUrl,
  timeout: 20000,
});

export const errorHandler = (err: AxiosError<Error>) => {
  const error = err as any;
  const errorMessage = error?.response?.data?.errors;

  if (err.response?.status === 400) {
    const errorType = typeof err?.response?.data?.message;
    if (errorType !== 'string') {
      throw new Error((err.response.data.message || [])[0]);
    }
    const error = err as any;
    if (Array.isArray(error?.response?.data?.errors)) {
      const message = error?.response?.data?.errors.join(',');
      throw new Error(message);
    }
    throw new Error(err.response.data.message);
  } else if (err.response?.status === 403) {
    const error = err as any;
    const errorMessage = error?.response?.data?.errors;

    if (errorMessage.includes('verify your account before performing this action')) {
      throw new Error('Verify your account before performing this action');
    } else {
      throw new Error('Session expired please login again');
    }
  } else if (Array.isArray(errorMessage)) {
    const message = errorMessage.join(',');
    throw new Error(message);
  }
  if (axios.isAxiosError(err)) {
    throw new Error(err.response?.data.message);
  }
  throw new Error('Error Processing request, please check your internet connection');
};

export const getToken = () => {
  const token = localStorage.getItem('accessToken');
  return token;
};
